//blog
.flexslider {
  border: none;
  margin: 0;
  border-radius: 0;
  background: transparent;
}

.flex-control-nav.flex-control-paging {
  bottom: 25px;
  li {
    line-height: 1em;
    margin: 0 3px;
    a {
      background: @white;
      height: 12px;
      width: 12px;
      .box-shadow(none);
      &.flex-active, &:hover, &:focus {
        background: @color_1;
      }
    }
  }
}

.TzUserMedia,
.TzBlogMedia {
  margin: 0;
}

.block_info {
  padding: 30px 30px 40px;
  background: @white;
}

.TzUserArticleInfo,
.TzTagArticleInfo,
.TzArticleBlogInfo {
  font-size: 13px;
  color: @color_bb;
  margin-bottom: 25px;
  font-family: Lato;
  &.single_article {
    margin: 0;
  }
  > div, .content_rating {
    display: inline-block;
  }
  a {
    color: @color_ddd !important;
    &:hover {
      color: @color_1 !important;
    }
  }
  .content_rating {
    .muted {
      color: @color_ddd;
    }
  }
  .rating > .rating-item:hover span::before,
  .rating > .rating-item:hover ~ a span::before,
  .rating > .voted::before,
  .rating > .rating-item:hover::before,
  .rating > .rating-item:hover ~ .rating-item::before {
    color: @color_1;
  }
}

.TzDescription {
  margin-top: 25px;
  color: @color_8b;
  line-height: 1.7em;
  p {
    margin: 0;
  }
}

.TzReadmore {
  .button(25px 0 0, 10px 25px, @color_2, @white, @white, 14px, inherit, @color_1, 1px solid @color_2, 1px solid @color_1, 0);
}

.TzItem {
  margin-bottom: 40px;
}

.TzUserTitle,
.TzTagTitle,
.TzBlogTitle {
  font-size: 26px;
  font-weight: 600;
  a {
    color: @color_2;
    &:hover {
      text-decoration: none;
      color: @color_1;
    }
  }
}

.TzQuote {
  position: relative;
  .TzBlogMedia {
    &:before {
      background: @color_3;
      content: " ";
      height: 100%;
      opacity: 1;
      position: absolute;
      width: 100%;
    }
    img {
      opacity: 0.05;
    }
  }
  .block_info {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background: transparent;
    color: @white;
    text-align: center;
    i {
      font-size: 23px;
      color: @color_1;
      padding-bottom: 20px;
      display: block;
    }
    .text {
      font-size: 20px;
      font-style: normal;
      p {
        padding: 12px 0 15px;
        margin: 0;
      }
    }
    .author {
      color: @color_4;
      font-size: 13px;
      line-height: 1em;
      display: block;
      padding-top: 20px;
    }
  }
}



//portfolio
.style-gallery {
  .TzInner {
    padding: 0;
    margin: 0;
    border: none;
    position: relative;
    height: 480px;
    &:after {
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0;
      content: '';
      .transition(all 0.4s ease 0s);
    }
    &:hover {
      .line-right {
        &:before {
          .scaleX(1);
        }
        &:after {
          .scaleY(1);
        }
      }
      .line-left {
        &:before {
          .scaleX(1);
        }
        &:after {
          .scaleY(1);
        }
      }
      .TzPortfolioDescription {
        opacity: 1;
      }
      &:after {
        opacity: 1;
      }

    }
  }
  .TzPortfolioMedia {
    .transition(all 0.4s ease 0s);
    img {
      position: relative;
    }
  }
  .TzPortfolioDescription {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    .transition(all 0.4s ease 0s);
    z-index: 2;
  }
  div#infscr-loading {
    background-color: transparent;
    color: @white;
    padding: 10px 0;
    border-radius: 0;
  }
  .tzNomore {
    display: none;
  }
}

.tz-gallery {
  padding: 0;

  #tz_options {
    padding: 30px 0;
  }
  .option-combo {
    text-align: center;
    .option-set {
      a {
        border: 1px solid @white;
        padding: 10px 20px;
        background: transparent;
        color: @white;
        text-transform: uppercase;
        .transition(all 0.4s ease 0s);
        border-radius: 0;
        margin: 0 5px;
        &.selected, &:hover, &:focus {
          background: transparent !important;
          color: @color_1;
          border-color: @color_1;
        }
      }
    }
  }
  .TzPortfolioMedia {
    margin: 0;
  }
  .TzPortfolioDescription {
    padding: 15px;

    .TzPortfolioTitle {
      a {
        color: @white;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 22px;
        padding-bottom: 15px;
      }
    }
    .TzTag {
      color: @color_1;
      font-size: 18px;
      a {
        color: @color_1;

        text-transform: capitalize;
      }
    }
  }

}



.width_auto {
  .tz-row {
    > div {
      width: auto;
    }
  }
}

.tz_navigation_article {
  .pagenav {
    li {
      a {
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        border-color: #363d4f;
        background: #363d4f;
        color: @white;
        &:hover {
          background: @color_1;
          border-color: @color_1;
        }
      }
    }
  }
}

.TzArticleMedia {
  margin: 0;
  img {
    max-width: 100%;
  }
}

#comments-list-0 {
  .comment_item:first-child {
    padding-top: 5px !important;
  }
}

div#comments {
  .comments-list {
    margin: 0;
    height: auto;
    .comment-avatar {
      width: 150px;
      float: none;
      margin: auto;
      padding-right: 40px;
      vertical-align: middle;
      img {
        width: 100%;
        height: 110px;
        border: 5px solid @white !important;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        .box-shadow(0 0 5px rgba(0, 0, 0, 0.05));
      }
    }
    .comment-avatar, .comment-content {
      display: table-cell;
    }
    .comment-content {
      background: @white;
      padding: 30px;
      position: relative;
      .box-shadow(0 0 5px rgba(0, 0, 0, 0.05));
      &:after, &:before {
        right: 100%;
        top: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;

      }
      &:after {
        border-color: rgba(255, 255, 255, 0);
        border-right-color: #ffffff;
        border-width: 13px;
        margin-top: -13px;
        //.box-shadow(0 0 5px rgba(0, 0, 0, 0.05));
      }
      &:before {
        border-color: rgba(255, 255, 255, 0);
        border-right-color: #e1e1e1;
        border-width: 14px;
        margin-top: -14px;
      }
      p {
        font-size: 14px;
        line-height: 1.7em;
        padding-bottom: 0;
        margin: 0;
        color: #8b8b8b;
      }
    }
    .comment_item {
      margin: 0;
      //border-bottom: 1px dotted @color_1;
      padding-bottom: 15px;
      padding-top: 15px;
    }
    .comments-list {
      margin-left: 100px;
      .comment_item {
        //padding-top: 30px;
        .tz-icon-comment-child {
          display: block;
        }
      }
    }
    .comment-author {
      padding-bottom: 20px;
      .j-author {
        padding-right: 12px;
        font-size: 16px;
        line-height: 1em;
      }
      .comment-meta {
        float: right;
      }
      .comment-meta {
        color: #cccccc;
        .j-date {
          color: #cccccc;
          font-size: 13px;
          font-family: Lato;
        }
        span, a {
          margin: 0 5px;
          font-weight: 300;
        }
        a {
          color: @color_1;
          &:hover {
            color: @color_1 !important;
          }
        }
      }
    }
  }
  #comments_content {
    padding-bottom: 40px;
  }
  .leave_comment {
    margin-bottom: 25px;
  }
  .comment {
    position: relative;
    .tz-icon-comment-child {
      font-size: 30px;
      color: @color_1;
      display: none;
      position: absolute;
      top: 50%;
      left: -50px;
      margin-top: -16px;

    }

  }
}

.comment-block {
  padding-left: 90px;
}

.comment-header {
  .vcard {
    font-weight: 600;
    font-style: normal;
    margin-right: 12px;
  }
  .comment-meta {
    color: @color_1;
    .time {
      font-size: 16px;
      color: @color_1;
      margin: 0 12px;
    }
    .reply {
      margin-left: 12px;
      font-size: 16px;
      color: @color_1;
    }
  }
}

.comment-content {
  p {
    padding: 8px 0;
    line-height: 1.5em;
    &:last-child {
      padding-bottom: 0;
    }
  }
}

#reply-title {
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  margin: 67px 0 26px;
  padding: 0;
  letter-spacing: 0.3px;
}

#comments-form {
  //.after-clear;
  > .row {
    margin: -10px;
    > div {
      padding: 10px;
    }
  }
  .comment-for-content {
    i {
      position: absolute;
      right: 20px;
      top: 20px;
      font-size: 18px;
      color: #eeeeee;
    }
  }
  .form-group {
    padding: 0;
    margin: 0;
    position: relative;
    i {
      position: absolute;
      right: 15px;
      top: 15px;
      font-size: 18px;
      color: #eeeeee;
    }
  }
  input[type='text'],
  input[type='email'],
  input[type='url'],
  textarea {
    background: white !important;
    padding: 5px 15px !important;
    height: 50px;
    display: block;
    //.placeholder(@base);
    border: 0;
    position: relative;
    width: 100%;
    .box-shadow(none);
    margin: 0;
    border-radius: 0;
    font-size: 14px !important;
    font-weight: 300 !important;
    line-height: 50px;
    color: #bbbbbb;

  }

  ::-webkit-input-placeholder {

    color: #bbbbbb;

  }
  :-moz-placeholder {
    color: #bbbbbb;

  }
  ::-moz-placeholder {
    /* Firefox 19+ */

    color: #bbbbbb;

  }
  :-ms-input-placeholder {
    color: #bbbbbb;

  }

  .comment-for-author,
  .comment-for-email {
    width: 50%;
    float: left;
  }
  .comment-for-url,
  .comment-for-submit,
  .comment-for-content {
    width: 100%;
    float: left;
  }
  .comment-for-email {
    //.after-clear;
  }
  input.author {
    //border-width: 1px 0 0 1px;
    //border-style: solid;
    //border-color: #e1e1e1;
  }
  input.email {
    //border-width: 1px 1px 0 1px;
    //border-style: solid;
    //border-color: #e1e1e1;
    cursor: default;
  }
  input.url {
    //width: 100%;
    //border-width: 1px;
    //border-style: solid;
    //border-color: #e1e1e1;

  }
  textarea {
    height: 150px;
    width: 100%;
    //border-width: 0 1px 1px 1px;
    //border-style: solid;
    //border-color: #e1e1e1;
    max-width: 100% !important;
    &:focus {
      outline-color: transparent;
      outline: none;
      background: @white !important;
      border-color: @white !important;
    }
  }
  .grippie {
    width: 100% !important;
    max-width: 100% !important;
    background: @white;
    border-color: @white;
  }
  a.submit,
  button.submit {
    //.button(@text-transform: none);
    letter-spacing: 3px;
    border-radius: 0;
    background: #363d4f;
    color: #eeeeee;
    padding: 13px 20px !important;
    margin: 13px 0 0 10px;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 1.5em;
  }
  .jcomment-captcha {
    margin-top: 30px;
    > * {
      margin-right: 30px !important;
    }
    img.captcha {
      margin: 0;
    }
    span.captcha {
      padding-left: 10px;
    }
    #comments-form-captcha {
      border: 1px solid @gray-light;
      width: auto !important;
      min-width: 200px;
      display: inline-block;
      height: 60px !important;
    }
  }
}

#comments-inline-edit.comments-inline-edit {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0 !important;
  border: medium none !important;
  margin: 0 !important;
  padding: 10px;
  button.submit, a.submit {
    width: 50%;
    display: inline-block;
    margin-right: 0;
  }
}

.TzLikeButtonInner {
  padding: 0;
  margin: 0;
  a {
    height: 35px;
    width: 35px;
    text-align: center;
    background: rgba(255, 255, 255, 0.6);
    display: inline-block;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    line-height: 1em;
    margin: 0 2px;
    i {
      line-height: 35px;
      font-size: 16px;
      color: #363d4f;
    }
    &:hover {
      background: rgba(255, 255, 255, 0.9);
    }
  }
}

.TzArticleTag {
  margin: 0;
  //padding: 10px 0;
  .title {
    display: inline-block;
    font-weight: bold;
    font-size: 16px;
    padding-right: 25px;
    color: #363d4f;
  }
  > span {
    display: inline-block;
  }
  a {
    color: #ffffff;
    font-size: 11px;
    padding: 12px 20px;
    background: #363d4f;
    display: inline-block;
    text-transform: uppercase;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    margin: 0 6px 0 0;
    letter-spacing: 1px;
    font-weight: 400;
    &:hover {
      color: @white;
      background: @color_1;
    }
  }
}

.tz_portfolio_user {
  margin: 0;
  color: #3d3d3d;
  .AuthorAvatar {
    margin: 0;
  }
  .media-body {
    padding: 0 30px;
    a {
      color: #363d4f;
      &:hover {
        color: @color_1;
      }
    }
  }
  .AuthorAvatar,
  .media-body {
    display: table-cell;
  }
}

.TzRelated {
  .TzTitle {
    color: #363d4f;
    margin: auto;
    padding: 0 0 3px 0;
    display: block;
    font-weight: 600;
    font-size: 18px;
    &:hover {
      color: @color_1;
    }
  }
  .tz-content {
    padding: 15px 20px 20px;
    background: @white;
  }
  .tz-desc {
    color: #8b8b8b;
    line-height: 1.8em;
  }
  .tz-info {
    padding: 0 0 8px;
    font-size: 13px;
    font-family: Lato;
    color: #bbbbbb;
  }
  .TzImage {
    height: 200px;
    overflow: hidden;
    img {
      position: relative;
    }
  }
  .owl-nav {
    position: absolute;
    top: -55px;
    right: 0;
    background: #f7f7f7;
    padding-left: 20px;
    > div {
      display: inline-block;
      width: 22px;
      height: 22px;
      line-height: 1em;
      background: #3b3b3b;
      color: @white;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      i {
        line-height: 22px;
        font-size: 14px;
      }
      &:hover {
        background: @color_1;
      }
      &.owl-prev {
        margin-right: 5px;
      }
    }
  }
}

.tz-style-title {
  font-weight: 400;
  font-size: 26px;
  margin: 0;
  padding-bottom: 30px;
  position: relative;
  > span {
    position: relative;
    padding-right: 30px;
    background: #f7f7f7;
    z-index: 111;
    &:before {
      background: @color_1;
      border-radius: 50%;
      content: "";
      height: 6px;
      position: absolute;
      right: 0;
      width: 6px;
      top: 50%;
      margin-top: -2px;
    }

  }
  &:after {
    border-top: 1px solid #e1e1e1;
    content: "";
    height: 1px;
    left: 0;
    position: absolute;
    top: 25%;
    right: 0;
    //min-width: 200px;
  }
}

.TzItemPage {
  .flex-direction-nav a {
    height: 38px;
    width: 38px;
    border: 1px solid @white;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    line-height: 1em;
    text-align: center;
    opacity: 1 !important;
    &:before {
      content: none;
    }
    i {
      line-height: 36px;
      color: @white;
      font-size: 20px;
    }
    &:hover {
      background: #3b3b3b;
      border-color: #3b3b3b;
    }
  }
  .TzArticleTitle {
    margin: 0;
    font-weight: 600;
    color: #363d4f;
  }
  .text-article {
    color: #8b8b8b;
    p {
      line-height: 1.8em;
      margin-bottom: 25px;
    }
    a {
      color: @color_1;
    }
  }
  .image-float {
    float: left;
    padding-right: 30px;
  }
  .text-semibold {
    font-weight: 600;
  }
  .text-quote {
    color: @color_1;
    padding-left: 45px;
    position: relative;
    font-size: 16px;
    i {
      font-size: 26px;
      left: 0;
      position: absolute;
      top: 5px;
    }
  }
  .text-medium {
    font-weight: 500;
  }
  .text-italic {
    font-style: italic;
  }
  .pos-absoulte {
    position: absolute;
    &.right {
      top: 40px;
      right: 40px;
    }
    &.left {
      top: 40px;
      left: 40px;
    }
    .date-style2 {
      display: block;
    }
    .date-style1 {
      display: none;
    }
  }
  .date-style2 {
    display: none;
    text-align: center;
    color: @white;
    padding: 7px 0;
    border-color: @white;
    border-style: solid none;
    border-width: 2px;
    .day {
      font-size: 30px;
      line-height: 1em;
    }
    .month {
      font-size: 16px;
      font-weight: 600;
      text-transform: uppercase;
      line-height: 1em;
    }
    > span {
      display: block;
    }
  }
  .first-letter {
    &:first-letter {
      color: @color_1;
      font-size: 80px;
      font-weight: 600;
      float: left;
      margin-top: -5px;
      margin-right: 10px;
      margin-left: -4px;
      line-height: 1em;
    }
  }
}

