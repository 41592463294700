@media (max-width: 1366px) and(min-width: 1025px) {
  .home3 {
    .text-lagre {
      font-size: 30px;
    }
    .text-medium {
      font-size: 16px !important;
    }
    .text-small {
      font-size: 13px !important;
    }
    .tz_content {
      padding: 0 70px;
      .text.style1 {
        margin-bottom: 15px;
      }
      .content_custom {
        width: 100%;
      }
      .info {
        padding-bottom: 15px;
      }
    }

    .about-team-home3 {
      .row {
        padding: 0 15px;
      }
      .model-box-1 {
        padding: 0 0 10px 0;
        .team-img {
          margin-bottom: 10px;
        }
        .team-details {
          margin-bottom: 0;
        }
        .position {
          padding: 0;
        }
      }
      .team-socials li a i {
        font-size: 12px;
        height: 25px;
        line-height: 25px;
        width: 25px;
      }
    }
    .client_home3 {
      padding-top: 15px;
    }
  }
}

@media (max-width: 1366px) {
  .bg-right {
    display: none;
  }

  .home3 {
    .home3_title_offer {
      &:after {
        margin: 10px 0 20px;
      }
    }
    .text-center-repsonsive {
      text-align: center;
      &:after {
        left: auto;
        margin: 10px auto 20px;
      }
    }
  }
}