@media (max-width: 991px) {
  .tparrows.default.custom:after {
    height: 40px;
    width: 40px;
    line-height: 40px;
    font-size: 24px;
  }

  .menu-style-home-1 .nav li > a {
    font-size: 32px;
  }

  .TzContact {
    width: 90%;
  }

  .tzCountdown {
    .countdown-section {
      .countdown-amount {
        font-size: 80px;
      }
      .countdown-period {
        font-size: 13px;
      }
    }
  }

  .style3 .module-title .custom_text_mod {
    width: 80%;
  }

  .home3 {
    .about-team-home3 {
      .model-box-1 {
        padding: 30px 15px 0;
        .team-details, .team-img {
          margin: 0;
        }
        .team-details {
          margin-top: 20px;
        }
        .title {
          font-size: 18px;
        }
        .position {
          font-size: 16px;
        }
      }

      .team-socials li a i {
        height: 50px;
        width: 50px;
        line-height: 50px;
        font-size: 18px;
      }
    }
    .offer .tz-btn-readmore {
      padding: 5px 20px;
      font-size: 12px;
    }
  }

  .navbar-inner {
    .btn-menu {
      padding: 28px 0;
    }

  }

  .navbar-collapse {
    display: block !important;
    height: 0 !important;
    width: 0 !important;
    .plazart-megamenu {
      position: fixed;
      left: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.3);
      visibility: hidden;
      opacity: 0;
      overflow: auto !important;
      .transition(all 0.3s linear 0s);
    }
    &.in {
      .plazart-megamenu {
        opacity: 1;
        visibility: visible;
        bottom: 0;
      }
    }
  }

  .navbar-toggle {
    display: block !important;
    float: none !important;
    i {
      color: @white;
      font-size: 30px;
    }
  }

  .tz-fixed {
    .navbar-collapse .plazart-megamenu {
      background: @black;
    }
  }

  .plazart-mainnav {
    width: auto;
    display: inline-block;
    min-height: 0;
    div.btn-menu {
      display: block;
    }
    .plazart-megamenu {
      ul.level0 {
        text-align: left;
        margin: 0;
        li {
          display: block !important;
          a {
            padding: 10px 20px !important;

          }
        }
      }
      ul {
        ul {
          padding: 0 15px !important;
        }
      }
      .tz-mega-menu .mega-col-nav {
        margin: 0;
        .mega-group-ct {
          padding: 0 15px;
        }
      }
      .dropdown-menu .mega-nav > li a,
      .mega-nav > li a {
        color: @white;
        font-size: 16px;
      }
      .dropdown-menu {
        background: transparent;
        position: relative;
        .box-shadow(none);
        top: 0;
        float: none;
      }
      &.always-show {
        &.animate {
          .mega > .mega-dropdown-menu {
            .transform(none);
            .transform-origin(0);
          }
        }
        .mega > .mega-dropdown-menu {
          opacity: 1;
          display: block;
          left: 0;
        }
      }
    }
  }

  .tz-media,
  .tz-content {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .tz_parallax .quote-content {
    font-size: 35px;
  }

  .tz-absolute .tz-content {
    &.single_image {
      position: relative;
    }
  }

  .social_footer,
  .nav.tz-menu-footer {
    float: none;
    text-align: center;
    li {
      a {
        font-size: 18px;
      }
    }
  }
}

@media (max-width: 991px) and(min-width: 480px) {
  .owl-carousel.testimonial-slide {
    width: 100%;
  }

  .navbar-inner {
    .btn-menu {
      .menu-toggle {
        height: 24px;
      }
    }
  }

}