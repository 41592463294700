//border
.border-top-radius(@radius) {
  border-top-right-radius: @radius;
  -moz-border-top-right-radius: @radius;
  -webkit-border-top-right-radius: @radius;
  -ms-border-top-right-radius: @radius;
  -o-border-top-right-radius: @radius;
  border-top-left-radius: @radius;
  -moz-border-top-left-radius: @radius;
  -webkit-border-top-left-radius: @radius;
  -o-border-top-left-radius: @radius;
  -ms-border-top-left-radius: @radius;
}

.border-right-radius(@radius) {
  border-bottom-right-radius: @radius;
  border-top-right-radius: @radius;
}

.border-bottom-radius(@radius) {
  border-bottom-right-radius: @radius;
  border-bottom-left-radius: @radius;
}

.border-left-radius(@radius) {
  border-bottom-left-radius: @radius;
  border-top-left-radius: @radius;
}

.border-radius(@radius) {
  border-radius: @radius;
  -moz-border-radius: @radius;
  -webkit-border-radius: @radius;
  -ms-border-radius: @radius;
  -o-border-radius: @radius;
}

.border-bottom-right-radius(@radius) {
  border-bottom-right-radius: @radius;
  -moz-border-bottom-right-radius: @radius;
  -webkit-border-bottom-right-radius: @radius;
  -ms-border-bottom-right-radius: @radius;
  -o-border-bottom-right-radius: @radius;
}

//transform
.transform(@transform) {
  -webkit-transform: @transform;
  -ms-transform: @transform;
  -o-transform: @transform;
  transform: @transform;
}

//box-shadow
.box-shadow(@shadow) {
  -webkit-box-shadow: @shadow; // iOS <4.3 & Android <4.1
  box-shadow: @shadow;
}

.box-shadow_important(@shadow) {
  -webkit-box-shadow: @shadow !important; // iOS <4.3 & Android <4.1
  box-shadow: @shadow !important;
}

.box-shadow-muti(@shadow1, @shadow2) {
  -webkit-box-shadow: @shadow1, @shadow2; // iOS <4.3 & Android <4.1
  -o-box-shadow: @shadow1, @shadow2;
  -ms-box-shadow: @shadow1, @shadow2;
  -webkit-box-shadow: @shadow1, @shadow2;
  box-shadow: @shadow1, @shadow2;
}

//button
.button(@margin,@padding:2px 25px, @color: @color_2,@tz-color,@tz-color-hover,@tz-font-size, @text-transform: uppercase, @hover: @color_1,@tz-border,@tz-border-hover,@tz-radius) {
  background: @color;
  color: @tz-color !important;
  text-transform: @text-transform;
  padding: @padding;
  margin: @margin;
  display: inline-block;
  border: @tz-border;
  border-radius: @tz-radius;
  font-size: @tz-font-size;
  line-height: @line-height-large;
  .transition(all 0.4s ease 0s);
  &:hover,
  &:focus {
    color: @tz-color-hover !important;
    background: @hover;
    border: @tz-border-hover;
  }
}

//input
.input_select(@radius: 3px, @border-color: @gray-light, @height: 40px, @line-height: 40px, @padding: 0 15px) {
  width: 100%;
  height: @height;
  border: 1px solid @border-color;
  padding: @padding;
  .border-radius(@radius);
  line-height: @line-height;
  //  .placeholder(@color: @color_1);
}

.placeholder(@color: @base) {
  // Firefox
  &::-moz-placeholder {
    color: @color;
    border-color: @color;
    opacity: 1; // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526
  }
  &:-ms-input-placeholder {
    color: @color;
    border-color: @color;
  }
  // Internet Explorer 10+
  &::-webkit-input-placeholder {
    color: @color;
    border-color: @color;
  }
  // Safari and Chrome
}

//transition_2
.transition_2(@tran1, @tran2) {
  -webkit-transition: @tran1, @tran2;
  -moz-transition: @tran1, @tran2;
  -o-transition: @tran1, @tran2;
  transition: @tran1, @tran2;
}