body {
  font-size: 14px;
  &.loaded {
    #loading {
      visibility: hidden;
      opacity: 0;
    }
  }
}

#loading {
  background: @white;
  z-index: 999999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  visibility: visible;
  opacity: 1;
  .transition(all 0.5s linear 0s);
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.none-padding-rl {
  padding-left: 0;
  padding-right: 0;
}

a {
  &:hover, &:active, &:focus {
    text-decoration: none;
    text-outline: none;
  }
}

//clear
@media (min-width: 1200px) {
  .clear_ds {
    clear: both;
  }
}

@media (max-width: 1199px) and (min-width: 992px) {
  .clear_md {
    clear: both;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  .clear_sm {
    clear: both;
  }
}

@media (max-width: 480px) {
  .clear_xs {
    clear: both;
  }
}

//ds-table

.ds-table {
  display: table;
  width: 100%;
  height: 100%;
  .ds-table-cell {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
  }
}

//header
.tz-header {
  position: fixed;
  top: 80px;
  width: 100%;
  z-index: 99999;
  .transition(all 0.3s linear 0s);
  &.style_1 {
    background: rgba(0, 0, 0, 0.3);
    top: 70px;
    //.transition(all 0.2s linear 0s);
    &.tz-fixed {
      position: fixed;
      top: 0;
      background: @black;
      padding: 0;
      #tzlogo {
        padding: 15px 0;
      }
      .plazart-megamenu ul.level0 > li > a {
        padding: 25px 20px;
      }
      .navbar-inner .btn-menu {
        padding: 13px 0;
      }
    }
    #tzlogo {
      padding: 30px 0;
      display: block;
    }
  }
  &.tz-fixed {
    position: fixed;
    top: 0;
    background: @black;
    padding: 15px 0;
  }
  &.style_2 {
    &.tz-fixed {
      background: transparent;
      #tz-logo {
        visibility: hidden;
        opacity: 0;
      }
      .btn-menu {
        padding: 19px 15px;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.7);
        .transition(all 0.3 linear 0s);
        &:hover {
          background: @black;
        }
      }
    }
  }
}

//custom banner
.banner_custom_text {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  .custom_text_banner {
    color: @white;
    text-transform: uppercase;
    font-weight: bold;
    &.style_1 {
      font-size: 66px;
      &:before {
        content: '';
        position: absolute;
        background: @color_1;
        height: 5px;
        width: 172px;
        left: 40%;
        top: 50%;
        margin-top: -15px;
        margin-left: -16px;
      }
    }
    &.style_2 {
      font-size: 20px;
      letter-spacing: 3px;
    }
  }
}

//breadcrumb
.tz-breadcrumb {
  h2.bread_last {
    text-align: center;
    color: @white;
    font-size: 50px;
    text-transform: uppercase;
    margin: 30px 0 10px 0;
  }
  .breadcrumb {
    margin: 0;
    background: transparent;
    border-radius: 0;
    padding: 0;
    text-align: center;
    li {
      a, span {
        color: @white;
        &:hover, &:focus, &:active {
          text-decoration: none;
        }
      }
    }
    > li + li:before {
      color: @color_1;
      margin: 0;
      padding: 0 5px 0 10px;
    }
  }
}

//blog
.flexslider {
  border: none;
  margin: 0;
  border-radius: 0;
  background: transparent;
}

.flex-control-nav.flex-control-paging {
  bottom: 25px;
  li {
    line-height: 1em;
    margin: 0 3px;
    a {
      background: @white;
      height: 12px;
      width: 12px;
      .box-shadow(none);
      &.flex-active, &:hover, &:focus {
        background: @color_1;
      }
    }
  }
}

.TzUserMedia,
.TzBlogMedia {
  margin: 0;
}

.block_info {
  padding: 30px 30px 40px;
  background: @white;
}

.TzUserArticleInfo,
.TzTagArticleInfo,
.TzArticleBlogInfo {
  font-size: 13px;
  color: @color_bb;
  margin-bottom: 25px;
  font-family: Lato;
  &.single_article {
    margin: 0;
  }
  > div, .content_rating {
    display: inline-block;
  }
  a {
    color: @color_ddd !important;
    &:hover {
      color: @color_1 !important;
    }
  }
  .content_rating {
    .muted {
      color: @color_ddd;
    }
  }
  .rating > .rating-item:hover span::before,
  .rating > .rating-item:hover ~ a span::before,
  .rating > .voted::before,
  .rating > .rating-item:hover::before,
  .rating > .rating-item:hover ~ .rating-item::before {
    color: @color_1;
  }
}

.TzDescription {
  margin-top: 25px;
  color: @color_8b;
  line-height: 1.7em;
  p {
    margin: 0;
  }
}

.TzReadmore {
  .button(25px 0 0, 10px 25px, @color_2, @white, @white, 14px, inherit, @color_1, 1px solid @color_2, 1px solid @color_1, 0);
}

.TzItem {
  margin-bottom: 40px;
}

.TzUserTitle,
.TzTagTitle,
.TzBlogTitle {
  font-size: 26px;
  font-weight: 600;
  a {
    color: @color_2;
    &:hover {
      text-decoration: none;
      color: @color_1;
    }
  }
}

.TzQuote {
  position: relative;
  .TzBlogMedia {
    &:before {
      background: @color_3;
      content: " ";
      height: 100%;
      opacity: 1;
      position: absolute;
      width: 100%;
    }
    img {
      opacity: 0.05;
    }
  }
  .block_info {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background: transparent;
    color: @white;
    text-align: center;
    i {
      font-size: 23px;
      color: @color_1;
      padding-bottom: 20px;
      display: block;
    }
    .text {
      font-size: 20px;
      font-style: normal;
      p {
        padding: 12px 0 15px;
        margin: 0;
      }
    }
    .author {
      color: @color_4;
      font-size: 13px;
      line-height: 1em;
      display: block;
      padding-top: 20px;
    }
  }
}

//mod
.style1 {

  .module-title {
    margin-bottom: 25px;
    position: relative;
    &:after {
      border-top: 1px solid @color_e1;
      content: "";
      height: 1px;
      left: 0;
      position: absolute;
      top: 50%;
      margin-top: 1px;
      width: 100%;

    }
    > span {
      position: relative;
      font-size: 20px;
      padding-right: 30px;
      display: inline-block;
      background: #f7f7f7;
      z-index: 1111;
      &:before {
        background: @color_1;
        border-radius: 50%;
        content: "";
        height: 7px;
        position: absolute;
        right: 0;
        width: 7px;
        top: 50%;
        margin-top: -3px;
      }
      &:after {

      }
    }
  }
}

.style2 {
  .module-title {
    color: @color_e1;
    font-size: 16px;
    text-transform: uppercase;
    margin-top: 10px;
    position: relative;
  }
  &.ver1 {
    .module-title {
      margin-bottom: 60px;
      &:before {
        content: ' ';
        border-bottom: 1px solid @color_1;
        position: absolute;
        top: 30px;
        width: 80px;
        left: 0;
      }
    }
  }
  &.ver2 {
    .module-title {
      margin-bottom: 10px;
    }
  }

  ul.mod_tz_tag {
    margin-bottom: 30px;
    li a {
      background: @color_3f;
      color: @color_4;
    }
  }
  .tztwd-tweets .avatar-info i {
    background: @color_3f;
    color: @color_4;
  }
  div.tztwd-tweet-container {
    border-color: @color_3f !important;
    &:last-child {
      border: none !important;
    }
  }

}

.tz-color-white {
  .custom_text_mod {
    color: @white !important;
  }
}

.style3 {
  .module-title {
    margin: 0;
    z-index: 11;
    .mod_title {
      padding-bottom: 5px;
      font-size: 30px;
      font-weight: 600;
      text-transform: uppercase;
      display: inline-block;
      position: relative;
      color: #3d3d3d;
      line-height: 1.3em;
    }

    &:after {
      content: '';
      display: block;
      width: 50px;
      height: 3px;
      background: #3d3d3d;
      margin: 15px auto;
    }
    .custom_text_mod {
      display: block;
    }
  }
  .custom_text_mod_top {
    padding-bottom: 13px;
    font-weight: 600;
    color: @color_1;
    text-align: center;
  }
  .custom_text_mod {
    padding: 10px 0 23px;
    font-size: 15px;
    color: #888888;
    width: 65%;
    margin: auto;
    line-height: 1.7em;
    text-align: center;
  }
  &.ver2 {
    .module-title {
      position: relative;
      &:after {
        content: none;
      }
      .mod_title {
        position: relative;
        font-weight: 400;
        font-size: 40px;
        &:after {
          content: '';
          position: absolute;
          border-top: 3px solid @color_1;
          left: 80px;
          top: 23px;
          width: 145px;
        }
      }
    }
    .custom_text_mod {
      font-size: 18px;
      padding: 23px 0 10px;
    }
  }
  &.ver3 {
    .module-title {
      &:after {
        content: none;
      }
    }
    .custom_text_mod {
      font-size: 18px;
      color: #555555;
    }
  }
}

//aside right
.aside-right {
  .tz-module {
    margin: 30px 0;
    &:first-child {
      margin-top: 0;
    }
    h3.module-title {
      margin-top: 0;
    }
  }
}

.form-search {
  .search-query {
    width: 100%;
    font-size: 13px;
    background: @white;
    color: @color_bb;
    border-color: @white;
    border-radius: 50px;
    padding: 15px 20px;
    height: auto;
    .box-shadow(none);
    &:focus {
      border-color: @color_1;
    }
  }
}

#login-form {
  .form-group {
    margin-bottom: 5px;
  }
  .tz-submit {
    .input_select(2px, @color_7, auto, 1.3em, 10px 0);
    text-transform: uppercase;
    width: 100%;
    display: block;
    background: @color_7;
    color: @white;
  }
  .unstyled {
    float: right;
    list-style: none;
    padding: 0;
    margin: 20px 0 0;
    li {
      a {
        color: @color_1;
        text-decoration: underline;
        &:hover {
          color: @color_1;
          text-decoration: none;
        }
      }
    }
  }
  .input-group {
    width: 100%;
    i {
      position: absolute;
      top: 13px;
      right: 15px;
      color: @color_5;
      z-index: 99;
    }
    input {
      .input_select(@radius: 2px, @border-color: @color_e7, @height: auto, @line-height: 1.3em, @padding: 10px 15px);
      .box-shadow(none);
      color: @color_6;
    }
  }
}

ul.category-menu,
.archive-module {
  list-style: outside none none;
  padding: 0;
  li {
    border-bottom: 1px dashed @color_e1 !important;
    padding: 8px 0 !important;
    &:first-child {
      padding-top: 0;
    }
    a {
      color: @color_2;
      font-size: 15px;
      font-weight: 600;
      &:hover {
        color: @color_1;
        text-decoration: none;
      }
      .count {
        float: right;
        color: @white;
        background: @color_1;
        height: 20px;
        width: 20px;
        line-height: 20px;
        text-align: center;
        font-size: 11px;
        border-radius: 2px;
      }
    }
  }
}

ul.category-menu li ul.sub-menu-category {
  display: block;
  padding: 8px 0 0;
  list-style: none;
  li {
    border-top: 1px dashed @color_e1;
    border-bottom: none;
    margin: 0;
    &:first-child {
      padding-top: 8px;
    }
    &:last-child {
      padding-bottom: 0;
    }
  }
}

div.tztwd-tweet-container {
  padding: 20px 0 15px;
  border-bottom: 1px dashed @color_e1 !important;
  &:last-child {
    border-bottom: none;
  }
  &:first-child {
    padding-top: 0;
  }
}

.tztwd-header {
  padding: 0 0 10px;
}

.tztwd-tweets {
  font-size: 14px;
  .avatar-info, .tweet-detail {
    display: table-cell;
    vertical-align: top;
  }
  .avatar-info {
    width: 40px;
    i {
      color: @white;
      font-size: 16px;
      padding: 6px;
      background: @color_1;
      border-radius: 50%;
    }
  }
}

.tztwd-tweet-data {
  font-size: 13px;
  text-align: left;
  margin-top: 5px;
}

ul.mod_tz_tag {
  margin: -3px -2px;
  li {
    padding: 3px 2px;
    display: inline-block !important;
    a {
      color: @white;
      font-size: 10px;
      padding: 8px 15px;
      background: @color_2;
      display: block;
      text-transform: uppercase;
      &:hover {
        color: @color_1;
        text-decoration: none;
      }
    }
  }
}

.latestnews {
  li {
    border-bottom: 1px dashed @color_e1;
    padding: 10px 0 15px;
    &:first-child {
      padding-top: 0;
    }
    a {
      color: @color_8b;
      margin-bottom: 10px;
      &:hover {
        color: @color_1;
        text-decoration: none;
      }
    }
    .created {
      margin-top: 10px;
      color: @color_bb;
      font-size: 13px;
    }
  }
}

.tz_item_default {
  .tz_description {
    color: @color_8b;
    p {
      line-height: 1.7em;
    }
  }
  .tz_readmore {
    a, i {
      color: @color_1;
      text-decoration: none;
    }
  }
}

.pagination {
  display: block;
  text-align: center;
  margin: 0;
  .pagination {
    display: inline-block;
  }
  .pagination-list {
    li {
      display: inline-block;
      a {
        border: none;
        background: transparent;
        color: @color_2;
        margin: 0;
        font-size: 16px;
        &:hover {
          background: transparent;
          color: @color_1;
        }
      }
      &.active {
        a {
          color: @color_1;
        }
      }
    }
    &:after {
      content: '';
      display: block;
      clear: both;
    }
  }
}

.text-custom-bottom {
  p {
    color: @white;
    font-size: 36px;
    font-weight: 500;
    margin: 0 0 7px 0;
    padding: 0;
    line-height: 1.2em;
    &.text-semibold {
      font-weight: 600;
    }
  }
  a {
    .button(0,@padding:15px 20px, @color: @color_2, @white, @color_1, 12px, @text-transform: uppercase, @hover: @color_2, none, none, 0);
    float: right;
    letter-spacing: 2px;
  }
}

.flickr {
  padding: 0;
  margin: -6px;
  a {
    position: relative;
    display: block;

    &:before {
      color: @white;
      content: "\f067";
      font-family: FontAwesome;
      font-size: 20px;
      left: 50%;
      margin: -14px 0 0 -8px;
      position: absolute;
      text-align: center;
      top: 50%;
      vertical-align: middle;
      z-index: 9;
      opacity: 0;
      .transition(all 0.3s ease 0s);
    }
    &:after {
      background: @color_1;
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
      opacity: 0;
      .transition(all 0.3s ease 0s);
    }
    &:hover {
      &:before {
        opacity: 1;
      }
      &:after {
        opacity: 0.9;
      }
    }
  }
}

//portfolio
.style-gallery {
  .TzInner {
    padding: 0;
    margin: 0;
    border: none;
    position: relative;
    height: 480px;
    &:after {
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0;
      content: '';
      .transition(all 0.4s ease 0s);
    }
    &:hover {
      .line-right {
        &:before {
          .scaleX(1);
        }
        &:after {
          .scaleY(1);
        }
      }
      .line-left {
        &:before {
          .scaleX(1);
        }
        &:after {
          .scaleY(1);
        }
      }
      .TzPortfolioDescription {
        opacity: 1;
      }
      &:after {
        opacity: 1;
      }

    }
  }
  .TzPortfolioMedia {
    .transition(all 0.4s ease 0s);
    img {
      position: relative;
    }
  }
  .TzPortfolioDescription {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    .transition(all 0.4s ease 0s);
    z-index: 2;
  }
  div#infscr-loading {
    background-color: transparent;
    color: @white;
    padding: 10px 0;
    border-radius: 0;
  }
  .tzNomore {
    display: none;
  }
}

.tz-gallery {
  padding: 0;

  #tz_options {
    padding: 30px 0;
  }
  .option-combo {
    text-align: center;
    .option-set {
      a {
        border: 1px solid @white;
        padding: 10px 20px;
        background: transparent;
        color: @white;
        text-transform: uppercase;
        .transition(all 0.4s ease 0s);
        border-radius: 0;
        margin: 0 5px;
        &.selected, &:hover, &:focus {
          background: transparent !important;
          color: @color_1;
          border-color: @color_1;
        }
      }
    }
  }
  .TzPortfolioMedia {
    margin: 0;
  }
  .TzPortfolioDescription {
    padding: 15px;

    .TzPortfolioTitle {
      a {
        color: @white;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 22px;
        padding-bottom: 15px;
      }
    }
    .TzTag {
      color: @color_1;
      font-size: 18px;
      a {
        color: @color_1;

        text-transform: capitalize;
      }
    }
  }

}

@media (min-width: 640px) {
  .tz_extra_landscape {
    .line-left {
      &:before {
        width: 94%;
        left: 2%;
      }
    }
    .line-right {
      &:before {
        width: 94%;
        right: 2%;
      }
    }
  }

  .tz_landscape {
    .line-left {
      &:before {
        width: 90%;
        left: 3%;
      }
    }
    .line-right {
      &:before {
        width: 90%;
        right: 3%;
      }
    }
  }

  .tz_portrait {
    .line-left {
      &:after {
        top: 2%;
        height: 90%;
      }
    }
    .line-right {
      &:after {
        height: 90%;
        bottom: 3%;
      }
    }
  }
}

.line-left {
  height: 100%;
  margin: 0;
  top: 20px;
  left: 20px;
  position: absolute;
  width: 100%;
  z-index: 1;
  &:after {
    width: 1px;
    height: 80%;
    position: absolute;
    top: 5%;
    left: 0;
    content: '';
    background: @color_1;
    .scaleY(0);
    .transition(all 0.4s ease 0s);
    .transform-origin(top center);
  }
  &:before {
    width: 80%;
    height: 1px;
    position: absolute;
    top: 0;
    left: 5%;
    content: '';
    background: @color_1;
    .scaleX(0);
    .transition(all 0.4s ease 0s);
    .transform-origin(left center);
  }
}

.line-right {
  height: 100%;
  margin: 0;
  right: 20px;
  bottom: 20px;
  position: absolute;
  width: 100%;
  z-index: 1;
  &:before {
    width: 80%;
    height: 1px;
    position: absolute;
    bottom: 0;
    right: 5%;
    content: '';
    background: @color_1;
    .scaleX(0);
    .transition(all 0.4s ease 0s);
    .transform-origin(right center);
  }
  &:after {
    width: 1px;
    height: 80%;
    position: absolute;
    bottom: 5%;
    right: 0;
    content: '';
    background: @color_1;
    .scaleY(0);
    .transition(all 0.4s ease 0s);
    .transform-origin(bottom center);
  }
}

/*----- page service ---*/

//what we do
.mod_tz_news_gird {
  padding-top: 20px;
  //.row:last-child {
  //  .tz_item_gird {
  //    padding-bottom: 0;
  //  }
  //}
}

.box-lagre {
  .tz_item_gird {
    .tz_title {
      font-size: 20px;
      font-weight: 700;
      margin-top: 39px;
      display: block;
      margin-bottom: 19px;
      a {
        font-weight: 700;
      }
    }
  }
}

.tz_item_gird {
  padding-bottom: 50px;
  .tz_image {
    img {
      width: 100%;
    }
    position: relative;
    margin-bottom: 25px;
    &:hover {
      .overlay {
        visibility: visible;
        opacity: 1;
        &:before {
          top: 12px;
          left: 12px;
          bottom: 12px;
          right: 12px;
        }
        a {
          .scale(1);
        }
      }
      .tz_date {
        opacity: 0;
      }
    }
  }
  .tz_title {
    margin-top: 0;
    line-height: 1.2em;
    a {
      font-weight: 600;
      text-transform: uppercase;
      color: #252525;
    }
  }
  .tz_description {
    color: #888888;
    line-height: 1.7em;
  }
  .tz_readmore {
    position: relative;
    display: block;
    text-transform: uppercase;
    color: @color_1;
    font-weight: 400;
    font-size: 13px;
    padding-top: 20px;
    &:after, &:before {
      content: '';
      width: 40px;
      height: 1px;
      display: inline-block;
      position: relative;
      top: -4px;
    }
    &:after {
      margin-left: 5px;
      background: rgba(215, 215, 215, 1);
      background: -moz-linear-gradient(left, rgba(215, 215, 215, 1) 0%, rgba(255, 255, 255, 1) 100%);
      background: -webkit-gradient(left top, right top, color-stop(0%, rgba(215, 215, 215, 1)), color-stop(100%, rgba(255, 255, 255, 1)));
      background: -webkit-linear-gradient(left, rgba(215, 215, 215, 1) 0%, rgba(255, 255, 255, 1) 100%);
      background: -o-linear-gradient(left, rgba(215, 215, 215, 1) 0%, rgba(255, 255, 255, 1) 100%);
      background: -ms-linear-gradient(left, rgba(215, 215, 215, 1) 0%, rgba(255, 255, 255, 1) 100%);
      background: linear-gradient(to right, rgba(215, 215, 215, 1) 0%, rgba(255, 255, 255, 1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#d7d7d7', endColorstr='#ffffff', GradientType=1);
    }
    &:before {
      margin-right: 5px;
      background: rgba(255, 255, 255, 1);
      background: -moz-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(215, 215, 215, 1) 100%);
      background: -webkit-gradient(left top, right top, color-stop(0%, rgba(255, 255, 255, 1)), color-stop(100%, rgba(215, 215, 215, 1)));
      background: -webkit-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(215, 215, 215, 1) 100%);
      background: -o-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(215, 215, 215, 1) 100%);
      background: -ms-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(215, 215, 215, 1) 100%);
      background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(215, 215, 215, 1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#d7d7d7', GradientType=1);
    }
  }
  .overlay {
    background: rgba(0, 0, 0, 0.5);
    &:before {
      background: rgba(255, 255, 255, 0.5);
    }
    a {
      top: 50%;
      position: absolute;
      left: 50%;
      width: 30px;
      height: 30px;
      display: inline-block;
      line-height: 30px;
      background: transparent;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      color: @white;
      font-size: 30px;
      margin: -15px 0 0 -15px;
      .scale(0.5);
      .transition(all 0.3s linear 0s);
      &:hover {
        color: @color_1;
      }
    }
  }
  .tz_date {
    position: absolute;
    top: 0;
    left: 0;
    background: @color_1;
    color: #f8f8f8;
    text-transform: uppercase;
    font-weight: bold;
    padding: 5px 10px;
    font-size: 14px;
    line-height: 1.8em;
    opacity: 1;
    .transition(all 0.4s ease 0s);
  }
}

.feature-project {
  .tz_item_gird {
    .tz_title {
      font-size: 20px;
      margin-bottom: 15px;
    }
    .tz_description {
      line-height: 1.8em;
    }
  }
}

//parallax

.parallax {
  background-attachment: fixed !important;
  background-position: 50% 0;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  overflow: hidden;
  position: relative;
}

.tz_parallax {
  .quote-content {
    font-weight: 600;
    font-size: 44px;
    text-transform: uppercase;
    color: #e1e1e1;
    text-align: center;
  }
}

.parallax_overlay {
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: block;
  }
  > * {
    z-index: 2;
    position: relative;
  }
}

.quote_style_1 {
  font-size: 30px;
  color: @white;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding: 55px 0 45px;
}

.quote_author_style_1 {
  font-weight: 600;
  font-size: 18px;
  color: @color_1;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.quote_icon {
  color: @color_1;
  font-size: 27px;
}

//offer
.home3 {
  .offer {
    .flexslider {
      padding: 0;
      border: none;
      background: transparent;
    }
  }
}

.tz-custom-list {
  li {
    padding-left: 30px;
    position: relative;
    font-size: 14px;
    color: @color_8b;
    line-height: 1.5em;
    padding-bottom: 10px;
    &:before {
      display: block;
      font-family: FontAwesome;
      position: absolute;
      left: 0;
    }
    &.tz-yes:before {
      content: '\f00c ';
      color: #84be20;
    }
    &.tz-no:before {
      content: '\f00d ';
      color: #f95b46
    }
  }
}

.offer {
  overflow: hidden;
  .text {
    line-height: 1.6em;
    &.text-1 {
      font-size: 18px;
      color: #555555;
      margin-bottom: 27px;
    }
    &.text-2 {
      font-size: 15px;
      color: #888888;
      margin-bottom: 33px;
    }
  }
  .title_offer {
    text-transform: uppercase;
    font-size: 30px;
    color: #3d3d3d;
    margin: 0 0 80px 0;
    position: relative;
    a {
      color: #3d3d3d;
    }
    &:after {
      content: '';
      position: relative;
      display: block;
      top: 20px;
      left: 0;
      width: 50px;
      height: 3px;
      background: #3d3d3d;
    }
  }
  .tz-btn-readmore {
    margin-top: 14px;
    padding: 10px 35px;
    background: @color_1;
    color: @white;
    text-transform: uppercase;
    display: inline-block;
    line-height: 1.7em;
    .box-shadow(0px -3px 1px rgba(0, 0, 0, 0.2) inset);
  }
  .flexslider {
    margin: 0;
    border: 1px solid @color_e1;
    padding: 10px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    .flex-control-nav {
      bottom: 20px;
      li {
        margin: 0 5px;
        a {
          border-radius: 0;
        }
      }
    }
    .flex-direction-nav {
      a {
        background: @white;
        height: 30px;
        width: 30px;
        margin: -15px 0 0;
        line-height: 30px;
        &:hover {
          background: @color_1;
          &:before {

            color: @white;
          }
        }
        &:before {
          width: 100%;
          text-align: center;
          font-family: FontAwesome;
          color: #252525;
          font-size: 14px;
        }
        &.flex-next:before {
          content: '\f105';

        }
        &.flex-prev:before {
          content: '\f104';
        }
      }
    }
    &:hover {
      .flex-direction-nav {
        .flex-next, .flex-prev {
          opacity: 1;
        }
        .flex-next {
          right: 20px;
        }
        .flex-prev {
          left: 20px;
        }
      }
    }
  }
}

.tz-absolute {
  .tz-media {
    float: none;
    text-align: center;
    opacity: 0.15;
    img {
      max-width: 100%;
      margin: auto;
      display: block;

    }
  }
  .tz-content {
    float: none;
    position: absolute;
    top: 0;
    bottom: 0;
  }
}

//models
.recent-model {
  .models {
    padding-top: 40px;
    .model-box {
      .team-details {
        .title {
          font-weight: 400;
          color: @white;
        }
      }
    }
  }
}

.models {
  .model-box {
    img {
      position: relative;
    }
    .team-details {
      padding: 35px 10px 0;
      .title {
        font-size: 20px;
        color: #252525;
        margin: 0;
        padding-bottom: 10px;
      }
      .position {
        color: @color_1;
        font-size: 16px;
        line-height: 1.6em;
      }
      .position, .title {
        text-transform: uppercase;
        font-weight: 600;
      }
    }
    &:hover {
      .overlay {
        opacity: 1;
        visibility: visible;
        &:before {
          top: 12px;
          left: 12px;
          bottom: 12px;
          right: 12px;
        }
      }
    }
    .overlay {
      background: transparent;
      z-index: 111;
      &:before {
        background: rgba(0, 0, 0, 0.5);
      }
      a {
        background-color: #ced0d1;
        border: 1px solid #ced0d1;
        display: inline-block;
        border-radius: 50%;
        height: 35px;
        width: 35px;
        text-align: center;
        line-height: 35px;
        color: #111111;
        position: absolute;
        bottom: 30px;
        margin: 0 4px;
        &:hover {
          color: #eeeeee;
          background: @color_1;
          border-color: @color_1;
        }
        &.pretty {
          left: 50%;
          margin-left: -40px;
        }
        &.link {
          right: 50%;
          margin-right: -40px;
        }
      }
    }
    .team-img {
      width: 100%;
      overflow: hidden;
      position: relative;
      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.3);
        opacity: 0;
        .transition(all 0.3s ease 0s);
        top: 0;
        left: 0;
      }
    }
    .option {
      position: absolute;
      top: 0;
      left: 50%;
      width: 0;
      height: 100%;
      padding: 10px;
      display: table;
      opacity: 0;
      .transition(all 0.3s ease 0s);
      z-index: 1;
      > span {
        width: 100%;
        height: 100%;
        margin: 10px;
        left: 0;
        background: rgba(0, 0, 0, 0.5);
        display: table-cell;
        text-align: center;
        vertical-align: bottom;
        > a {
          background-color: #ced0d1;
          border: 1px solid #ced0d1;
          display: inline-block;
          border-radius: 50%;
          height: 35px;
          width: 35px;
          text-align: center;
          line-height: 35px;
          color: #111111;
          position: absolute;
          bottom: 30px;
          margin: 0 4px;
          &:hover {
            color: #eeeeee;
            background: @color_1;
            border-color: @color_1;
          }
        }
      }
    }
  }
  .model-box-1 {
    .team-img {
      overflow: hidden;
      position: relative;
      margin-bottom: 25px;
      img {
        position: relative;
      }
    }
    .title {
      font-weight: 400;
      font-size: 18px;
      color: @white;
      text-transform: uppercase;
      margin: 0;
      padding-bottom: 5px;
      line-height: 1.4em;
      letter-spacing: 1px;
    }
    .position {
      font-weight: 600;
      font-size: 15px;
      color: @color_1;
      text-transform: uppercase;
      line-height: 1.5em;
      padding-bottom: 15px;
      letter-spacing: 1px;
    }
    .description {
      color: #EEEEEE;
      line-height: 1.8em;
    }
    .overlay {
      background: transparent;
      z-index: 111;
      &:before {
        background: rgba(0, 0, 0, 0.5);
      }
    }
    .team-socials {
      padding: 0 15px;
      li {
        display: inline-block;
        a {
          text-align: center;
          border-radius: 50%;
          border: 1px solid @white;
          display: block;
          margin: 5px;
          position: relative;
          z-index: 1111;
          &:hover {
            background: @color_1;
            border-color: @color_1;
          }
          i {
            color: @white;
            line-height: 35px;
            font-size: 18px;
            height: 35px;
            width: 35px;
          }
        }
      }
    }

    &:hover {
      .overlay {
        opacity: 1;
        visibility: visible;
        &:before {
          top: 12px;
          left: 12px;
          bottom: 12px;
          right: 12px;
        }
      }
    }
  }
  .owl-controls {
    //padding-top: 110px;
    margin-top: 0 !important;
  }
}

.owl-carousel {
  .owl-controls {
    .owl-dot {
      span {
        -webkit-border-radius: 0 !important;
        -moz-border-radius: 0 !important;
        border-radius: 0 !important;
        background: @white !important;
        opacity: 1 !important;
      }
      &.active {
        span {
          background: @color_1 !important;
        }
      }
    }
  }
}

//twitter parallax
.tztwd-twitter-icon {
  height: 95px;
  width: 95px;
  border-radius: 50%;
  line-height: 80px;
  text-align: center;
  position: relative;
  border: 6px solid rgba(255, 255, 255, 0.25);
  margin: auto;
  i {
    font-size: 30px;
    background: @color_1;
    color: @white;
    line-height: 83px;
    height: 83px;
    width: 83px;
    border-radius: 50%;
  }
}

.tztwd_parallax {
  .tztwd-tweets {
    padding-top: 30px;
  }
  .tztwd-header {
    padding: 20px 0;
    img {
      float: none;
    }

  }
  div.tztwd-tweet-container {
    border: none !important;
    text-align: center;
    line-height: 2.5em;
    padding: 0 !important;
    .tztwd-tweet-data,
    .tztwd-tweet {
      font-size: 24px;
      font-weight: 400;
    }
    .tztwd-tweet-data {
      margin: 0;
      a {
        &:hover {
          text-decoration: none;
          color: @white;
          font-weight: bold;
        }
      }
    }
  }
  .owl-theme {
    .owl-controls {
      margin-top: 60px;
      .owl-dot {
        span {
          border-radius: 0;
          background: @white;
          opacity: 1;
        }
        &.active {
          span {
            background: @color_1;
          }
        }
      }
    }
  }
}

//contact
.TzContact {
  width: 75%;
  margin: auto;
  #contact-form {
    input, textarea {
      width: 100%;
      background: transparent;
      color: @white;
      font-size: 16px;
      border-width: 0 0 2px 0;
      border-color: @white;
      padding: 15px 20px;
      margin-bottom: 30px;
      .transition(all 0.3s ease 0s);
      &:focus {
        border-color: transparent transparent @color_1 transparent;
        outline: none;
      }
    }
    .emailCopy {
      padding-top: 25px;
      input[type="checkbox"] {
        width: auto;
        margin: auto 10px auto auto;
        height: auto;

      }
      label {
        font-weight: 400;
        font-size: 15px;
        color: #EEEEEE;
        margin: 0;
      }
    }
    .form-actions {
      padding-top: 20px;
    }
    label {
      color: @white;
    }
    ::-webkit-input-placeholder {
      color: @white;
    }
    :-ms-input-placeholder {
      color: @white;
    }
  }
  #message-sent, #message-sent-false {
    display: none;
  }
  .tz-contact-button-bar {
    text-align: right;
  }
  .tz-contact-send {
    text-transform: uppercase;
    background: transparent;
    border: none;
    color: @white;
    position: relative;
    font-weight: 600;
    padding: 0;
    letter-spacing: 2px;
    font-size: 16px;
    &:before {
      content: '';
      border-bottom: 2px solid @color_1;
      display: block;
      position: absolute;
      width: 100%;
      bottom: -3px;
      left: 0;
    }
    &:after {
      content: '';
      border-bottom: 2px solid @color_1;
      position: absolute;
      width: 13px;
      display: block;
      .rotate(-30deg);
      bottom: -7px;
      right: 0;
    }
  }
  .mod_custom {
    padding-top: 125px;

  }
}

.clients {
  margin: 0;
  .owl-stage {
    img {
      margin: auto;
      width: auto !important;
      max-width: 100%;
    }
  }
}

//footer page
.single_custom_text {
  .page_image {
    padding-top: 20px;
    padding-bottom: 15px;
  }
  .page_social {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .page_info {
    color: #6e6b76;
    font-size: 15px;
    padding-top: 10px;
    line-height: 1.6em;
    font-weight: 500;
    p {
      margin: 0;
    }
  }
}

.page_social {
  a {
    height: 35px;
    width: 35px;
    border-radius: 50%;
    border: 1px solid #686570;
    color: #686570;
    font-size: 18px;
    line-height: 35px;
    text-align: center;
    display: inline-block;
    margin: 0 7px;
    &:hover {
      color: @white;
      border-color: @white;
    }
  }
}

//about-team
.about-team {
  .team-img {
    position: relative;
    img {
      width: 100%;
    }
    &:hover {
      .team-socials {
        opacity: 1;
      }
    }
    .team-socials {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0;
      background: rgba(0, 0, 0, 0.5);
      .transition(all 0.5s ease 0s);
      ul {
        li {
          display: inline-block;
          a {
            text-align: center;
            border-radius: 50%;
            border: 1px solid @white;
            display: block;
            margin: 0 5px;
            &:hover {
              background: @color_1;
              border-color: @color_1;
            }
            i {
              color: @white;
              line-height: 35px;
              font-size: 18px;
              height: 35px;
              width: 35px;
            }
          }
        }
      }
    }
  }
  .custom_text_header {
    font-size: 15px;
    color: #888888;
    margin-top: -4px;
    line-height: 1.7em;
    margin-bottom: 90px;
  }
  .team-details {
    padding-top: 25px;
    .title {
      margin-top: 0;
      font-size: 18px;
      font-weight: 600;
      text-transform: uppercase;
      line-height: 1.5em;
      margin-bottom: 5px;
    }
    .position {
      font-size: 15px;
      font-weight: 600;
      text-transform: uppercase;
      color: #aaaaaa;
      letter-spacing: 1px;
      padding-bottom: 10px;
    }
    .description {
      color: #888888;
      line-height: 1.7em;
      padding: 5px 0;
    }
  }
}

//testimonial
.tz_testimonial-multiple {
  margin-bottom: -60px;
  > div {
    padding-bottom: 60px;
  }
  .tz-media {
    width: 105px;
    .image {
      height: 105px;
      border-radius: 50%;
      background: #cccccc;
      border: 6px solid transparent;
    }
    img {
      max-width: 100%;
      border-radius: 50%;
      width: 100%;
    }
  }
  .tz-media, .tz-content {
    display: table-cell;
    vertical-align: top;
  }
  .tz-content {
    padding-top: 5px;
    padding-left: 20px;
    .quote {
      color: #888888;
      line-height: 1.7em;
      padding-bottom: 10px;
    }
    .author, .job {
      font-weight: 500;
    }
    .author {
      color: #252525;
    }
    .job {
      color: #aaaaaa;
    }
  }
}

//clients
.clients-grid {
  .client-wrap {
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-color: rgba(255, 255, 255, 0.3);
    border-right-style: solid;
    border-right-width: 1px;
    padding: 0;
    height: 150px;
    position: relative;
    width: 20%;
    float: left;
    .clients-logo {
      position: absolute;
      width: 100%;
      height: 100%;
    }
    &.last {
      border-right-width: 0;
    }
    &.no-border {
      border-bottom-width: 0;
    }
    img {
      max-width: 100%;
      display: block;
      margin: auto;
      vertical-align: middle;
    }
  }
  .first {
    clear: both !important;
  }
}

//ToTop
#toTop {
  position: relative;
  .toTop {
    position: absolute;
    right: 0;
    bottom: 200px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background: @color_1;
    width: 40px;
    height: 40px;
    text-align: center;
    i {
      font-size: 26px;
      color: #eeeeee;
      margin: auto;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      line-height: 40px;
    }
  }
}

//slide
.tp-bullets {
  &.custom {
    .bullet {
      width: 5px;
      height: 35px;
      margin: 0 5px;
      background: rgba(255, 255, 255, 0.8);
      display: inline-block;
      cursor: pointer;
      .transition(all 0.3s ease 0s);
      &:hover, &.selected {
        background: rgba(224, 0, 108, 0.8);
      }
    }
  }
}

.tparrows.default {
  &.custom {
    background: none;
    text-align: center;
    &:after {
      font-family: FontAwesome;
      color: @white;
      font-size: 32px;
      width: 60px;
      height: 60px;
      line-height: 60px;
      background: rgba(255, 255, 255, 0.1);
      display: block;
    }
    &:hover {
      &:after {
        background: @color_1;
      }
    }
    &.tp-leftarrow {
      &:after {
        content: '\f104';
      }
    }
    &.tp-rightarrow {
      &:after {
        content: '\f105';
      }
    }
  }
}

//menu home
.menu-style-home-1 {
  position: fixed;
  top: -70px;
  left: 0;
  bottom: -70px;
  right: 0;
  background: rgba(0, 0, 0, 0.85);
  visibility: hidden;
  opacity: 0;
  .transition_2(opacity 0.3s, visibility 0.3s);
  z-index: 9999;
  &.open {
    visibility: visible;
    opacity: 1;
  }
  .nav {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    li {
      text-align: center;
      &.current {
        a {
          color: @color_1;
          background: transparent;
        }
      }
      a {
        text-transform: uppercase;
        font-weight: 500;
        font-size: 36px;
        color: @white;
        padding: 0;
        -webkit-transition: color 0.3s, border-color 0.3s;
        -moz-transition: color 0.3s, border-color 0.3s;
        -ms-transition: color 0.3s, border-color 0.3s;
        -o-transition: color 0.3s, border-color 0.3s;
        transition: color 0.3s, border-color 0.3s;
      }
    }
  }
}

.btn-menu {
  display: inline-block;
  cursor: pointer;
  padding: 10px 0;
  .menu-toggle {
    position: relative;
    display: block;
    float: right;
    width: 30px;
    height: 22px;
    .bar {
      display: block;
      position: absolute;
      left: 0;
      width: 100%;
      height: 2px;
      background-color: @white;
      -webkit-transition: -webkit-transform 0.3s ease-out, opacity 0.3s;
      -moz-transition: -moz-transform 0.3s ease-out, opacity 0.3s;
      -o-transition: -o-transform 0.3s ease-out, opacity 0.3s;
      transition: -webkit-transform 0.3s ease-out, -moz-transform 0.3s ease-out, -o-transform 0.3s ease-out, transform 0.3s ease-out, opacity 0.3s;
      -webkit-transform-origin: center center;
      -moz-transform-origin: center center;
      -o-transform-origin: center center;
      -ms-transform-origin: center center;
      transform-origin: center center;
    }
    .bar1 {
      top: 0;
    }
    .bar2 {
      top: 50%;
      margin-top: -1px;
    }
    .bar3 {
      bottom: 0;
    }
    &.active {
      .bar1 {
        -webkit-transform: rotate(45deg) translateX(7px) translateY(7px);
        -moz-transform: rotate(45deg) translateX(7px) translateY(7px);
        -ms-transform: rotate(45deg) translateX(7px) translateY(7px);
        -o-transform: rotate(45deg) translateX(7px) translateY(7px);
        transform: rotate(45deg) translateX(7px) translateY(7px);
      }
      .bar2 {
        opacity: 0;
      }
      .bar3 {
        -webkit-transform: rotate(-45deg) translateX(7px) translateY(-7px);
        -moz-transform: rotate(-45deg) translateX(7px) translateY(-7px);
        -ms-transform: rotate(-45deg) translateX(7px) translateY(-7px);
        -o-transform: rotate(-45deg) translateX(7px) translateY(-7px);
        transform: rotate(-45deg) translateX(7px) translateY(-7px);
      }
    }
  }
  i {
    color: @white;
    font-size: 22px;
  }
}

//creative
.tz_creative {
  .tz_item_default {
    padding: 45px 0;
    display: block;
    float: none;
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      padding-bottom: 0;
    }
    .tz-content {
      padding: 30px 15px 0;
      .tz_category {
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 600;
        color: @color_1;
      }
      .tz_title {
        padding-bottom: 60px;
        position: relative;
        font-weight: 600;
        margin-top: 5px;
        line-height: 1.5em;
        &:after {
          content: '';
          display: inline-block;
          position: absolute;
          border-top: 3px solid #3d3d3d;
          width: 50px;
          top: 62px;
        }
        a {
          text-transform: uppercase;
          color: #3d3d3d;
        }
      }
    }
    .tz_description {
      .text-1 {
        font-size: 18px;
        line-height: 1.6em;
        color: #555555;
        padding-bottom: 18px;
      }
      .text-2 {
        font-size: 15px;
        color: #888888;
      }
    }
  }
  .tz-media {
    img {
      max-width: 100%;
    }
  }
  .eve {
    .tz-media {
      .tz_image {
        padding-right: 40px;
      }
    }
    .tz_title {
      &:after {
        left: 0;
      }
    }
  }
  .old {
    .tz-media, .tz-content {
      float: right;
    }
    .tz-content {
      text-align: right;
    }
    .tz-media {
      .tz_image {
        padding-left: 40px;
      }
    }
    .tz_title {
      &:after {
        right: 0;
      }
    }
  }
}

//footer

.tz-menu-footer-page {
  li {
    display: inline-block;
    margin: 0;
    padding: 12px 0;
    &:last-child {
      a {
        border-right: none;
      }
    }
    &.open > a:hover,
    &.open > a:focus,
    &.open > a {
      border-color: @black;
    }
    a {
      text-transform: uppercase;
      font-size: 13px;
      color: #5e5c63;
      border-right: 1px solid @black;
      padding: 0 15px;
      line-height: 1em;
      border-radius: 0;
      font-weight: 500;
    }
  }
}

.tz-footer-2 {
  border-bottom: 1px solid #0c0c0e;
}

.tz-menu-footer-1 {
  position: relative;
  &:after {
    border-top: 1px solid #2e2e33;
    content: '';
    position: absolute;
    width: 100%;
    top: 4px;
  }
}

.tz-footer {
  color: @color_8b;
  a {
    color: @color_8b;
  }
  .custom-text-footer {
    margin-top: 45px;
    p {
      margin: 15px 0;
      line-height: 1.7em;
      &:first-child {
        margin-top: 0;
      }
    }
    a {
      font-style: italic;
      color: @color_1;
      i {
        font-size: 6px;
      }
    }
  }
}

.social_footer {
  li {
    display: inline-block;
    a {
      color: @color_63;
      padding: 0 5px;
      &:hover {
        i {
          color: @color_1;
          .scale(1.3);
        }
      }
      i {
        font-size: 15px;
        line-height: 2.5em;
      }
    }
    &:first-child a {
      padding-left: 0;
    }
    &:last-child a {
      padding-right: 0;
    }
  }
}

.nav {
  li {
    &.open > a, &.open > a:hover, &.open > a:focus, > a:hover, > a:focus, &.active > a, &.active > a:hover {
      background: transparent;
      color: @color_1;
    }
  }
  &.tz-useful-menu {
    li {
      display: block;
      border-bottom: 1px dashed @color_3f;
      &:last-child {
        border-bottom: none;
      }

      &:before {
        content: '\f054 ';
        font-family: FontAwesome;
        top: 12px;
        position: absolute;
        color: @color_1;
        font-size: 10px;
      }
      a {
        line-height: 1.3em;
      }
    }
  }
  &.tz-menu-footer {
    float: right;
    li {
      display: inline-block;
      &:first-child {
        a {
          padding-left: 0;
        }
      }
      &:last-child {
        a {
          padding-right: 0;
        }
      }
      &.active {
        > a {
          color: @color_1;
        }
      }
      a {
        color: @color_63;
        padding: 5px 10px;
      }

    }
  }
}

//effect

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  .transition(opacity 0.2s linear 0s);
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    .transition(all 0.2s linear 0s);
  }
}

//count

.skill-count-item {
  padding: 15px 0;
  .tz_media, .tz_content {
    display: table-cell;
    vertical-align: middle;
  }
  .tz_content {
    border-left: 1px solid @color_1;
    padding-left: 15px;
    h2, p {
      margin: 0;
    }
    h2 {
      color: #3d3d3d;
      font-size: 40px;
      margin-top: 10px;
    }
    p {
      color: #252525;
      font-size: 13px;
      text-transform: uppercase;
      font-weight: 600;
      margin-bottom: 10px;
    }
  }
  .tz_media {
    padding-right: 15px;
  }
}

.custom_text_top_count {
  padding-bottom: 55px;
  .style_1 {
    font-size: 26px;
    line-height: 1.5em;
  }
}

//countdown
.mod_coutndown {
  color: #dddddd;
  .custom_text_countdown {
    .style_1 {
      font-size: 13px;
      font-weight: 600;
      text-transform: uppercase;
      color: @color_1;
    }
    .style_2 {
      font-size: 30px;
      font-weight: 600;
      text-transform: uppercase;
      color: @white;
      padding-bottom: 20px;
    }
    .style_3 {
      font-size: 18px;
      color: #dddddd;
      width: 70%;
      margin: auto;
      line-height: 1.6em;
    }
  }
  .custom_text_bottom_countdown {
    p {
      font-size: 28px;
      font-weight: 600;
      color: @white;
      text-transform: uppercase;
      padding-bottom: 25px;
    }
    a {
      color: @white;
      background: @color_1;
      padding: 10px 35px;
      font-size: 14px;
      font-weight: 400;
      -webkit-box-shadow: inset 0 -4px 2px rgba(0, 0, 0, 0.2);
      -moz-box-shadow: inset 0 -4px 2px rgba(0, 0, 0, 0.2);
      box-shadow: inset 0 -4px 2px rgba(0, 0, 0, 0.2);
      letter-spacing: 1px;
      text-transform: uppercase;
      display: inline-block;
      line-height: 1.8em;
    }
  }
}

.tzCountdown {
  margin: auto;
  text-align: center;
  padding: 55px 0 40px;
  .countdown-section {
    padding: 0 30px;
    .countdown-amount {
      font-weight: 200;
      font-size: 120px;
      color: #eeeeee;
      vertical-align: middle;
    }
    .countdown-period {
      padding-left: 10px;
      width: 20px;
      -ms-word-wrap: break-word;
      word-wrap: break-word;
      text-transform: uppercase;
      font-weight: 800;
      font-size: 15px;
      color: @color_1;
      display: inline-block;
      vertical-align: middle;
      text-align: center;
    }
  }
}

//what custom say
.owl-carousel.testimonial-slide {
  width: 60%;
  margin: auto;
  .owl-item {
    img {
      width: 110px;
      margin: auto;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      border: 6px solid rgba(255, 255, 255, 0.2);
    }
    .tz-content {
      color: @white;
      text-align: center;
      padding-top: 55px;
      padding-bottom: 35px;
      .quote {
        font-size: 16px;
        line-height: 1.6em;
        padding-bottom: 45px;
      }
      .author {
        font-size: 16px;
        text-transform: uppercase;
        line-height: 1.7em;
        padding-bottom: 5px;
        font-weight: 400;
      }
      .job {
        font-size: 14px;
        text-transform: uppercase;
        line-height: 1.3em;
        color: @color_1;
        font-weight: 600;
      }
    }
  }
}

//portfolio model
.tz_filter {
  text-align: center;
  padding-bottom: 60px;
  .option-combo {
    a {
      background: transparent !important;
      border: 1px solid #3b3b3b;
      color: #3b3b3b;
      text-transform: uppercase;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;
      margin: 0 5px;
      padding: 5px 15px;
      font-weight: 500;
      line-height: 1.7em;
      &.selected {
        color: @color_1;
        border-color: @color_1;
      }
    }
  }

}

.home_content_portfolio {
  margin: -15px;
  .element {
    background: transparent;
  }
  .TzInner {
    position: relative;
    overflow: hidden;
    border: none;
    padding: 0;
    margin: 15px;
    &:hover {
      .overlay {
        opacity: 1;
        visibility: visible;
        &:before {
          top: 15px;
          left: 15px;
          bottom: 15px;
          right: 15px;
        }
      }
    }
    .tz-image-portfolio {
      img {
        width: auto;
        position: relative;
      }
    }
    .tz-portfolio-content {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      .ds-table-cell {
        padding: 30px;
        z-index: 111;
        position: relative;
      }
      .tz_title {
        line-height: 1.7em;
        margin: 0;
        padding-bottom: 5px;
        a {
          color: @white;
          text-transform: uppercase;
        }
      }
      .tz_tag {
        a {
          color: @color_1;
          text-transform: uppercase;
          font-weight: 600;
          font-size: 14px;
        }
      }
      .option {
        position: absolute;
        bottom: 45px;
        width: 100%;
        left: 0;
        a {
          height: 35px;
          width: 35px;
          margin: 0 5px;
          text-align: center;
          background: rgba(255, 255, 255, 0.8);
          display: inline-block;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          border-radius: 50%;
          i {
            line-height: 35px;
            color: #111111;
            font-size: 17px;
          }
          &:hover {
            background: @color_1;
            i {
              color: #EEEEEE;
            }
          }
        }
      }
    }
    .overlay {
      background: rgba(0, 0, 0, 0.5);
      &:before {
        background: rgba(0, 0, 0, 0.4);
      }
    }
  }
}

.view-all-projects {
  padding-top: 75px;
  .title {
    font-size: 28px;
    color: #3d3d3d;
    font-weight: 600;
    text-transform: uppercase;
    padding-bottom: 40px;
  }
}

.button-style-1 {
  color: @white;
  background: @color_1;
  padding: 10px 35px;
  font-size: 14px;
  font-weight: 400;
  -webkit-box-shadow: inset 0 -4px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: inset 0 -4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 -4px 0 rgba(0, 0, 0, 0.1);
  letter-spacing: 1px;
  text-transform: uppercase;
  display: inline-block;
  line-height: 1.8em;
  border: 1px solid @color_1;
}

.button-style-2 {

  padding: 10px 35px;
  font-size: 14px;
  font-weight: 400;
  -webkit-box-shadow: inset 0 -4px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: inset 0 -4px 0 rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 -4px 0 rgba(0, 0, 0, 0.1);
  letter-spacing: 1px;
  text-transform: uppercase;
  display: inline-block;
  line-height: 1.8em;

}

.rev_slider_wrapper {
  .button-style-1 {
    color: @white;
    background: @color_1;
    border: 1px solid @color_1;
    &:hover {
      background: @white;
      color: @color_1;
      border-color: transparent;
    }
  }
  .button-style-2 {
    color: @black;
    border: 1px solid @white;
    background: @white;
  }
}

.tz-style-dots {
  .owl-dots {
    padding-top: 110px;
  }
}

//menu fixed
.tz-menu-fixed {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 330px;
  z-index: 99999;
  background: @black;
  //overflow-y: auto;
  padding: 20px 0 60px;
  visibility: hidden;
  opacity: 0;
  .transition(all 0.3s linear 0s);
  &.open {
    visibility: visible;
    opacity: 1;
  }
  #login-form {
    .tz-submit {
      border-color: @color_1;
      background: @color_1;
    }
    .unstyled li a {
      font-weight: 600;
    }
    .checkbox label {
      color: @white;
    }
  }
  .logo-menu {
    padding: 32px 0 30px;
    .btn-menu {
      float: right;
      position: relative;
      height: 10px;
      width: 24px;
      display: block;
      clear: both;
      top: -33px;
      right: -15px;
      .bar-1 {
        position: absolute;
        display: block;
        width: 100%;
        height: 1px;
        background: @white;
        top: 0;
        -webkit-transform: rotate(45deg) translateX(7px) translateY(7px);
        -moz-transform: rotate(45deg) translateX(7px) translateY(7px);
        -ms-transform: rotate(45deg) translateX(7px) translateY(7px);
        -o-transform: rotate(45deg) translateX(7px) translateY(7px);
        transform: rotate(45deg) translateX(7px) translateY(7px);
      }
      .bar-2 {
        position: absolute;
        display: block;
        background: @white;
        width: 100%;
        height: 1px;
        bottom: 0;
        -webkit-transform: rotate(-45deg) translateX(6px) translateY(-7px);
        -moz-transform: rotate(-45deg) translateX(6px) translateY(-7px);
        -ms-transform: rotate(-45deg) translateX(6px) translateY(-7px);
        -o-transform: rotate(-45deg) translateX(6px) translateY(-7px);
        transform: rotate(-45deg) translateX(6px) translateY(-7px);
      }
    }
  }
  .form-search .search-query {
    background: #252525;
    color: #bbbbbb;
    border-color: #252525;
  }
  .archive-module {
    margin-top: -15px;
    padding-bottom: 15px;
    li a {
      color: #8b8b8b;
      &:hover {
        color: @white;
      }
    }
  }
  .archive-module li,
  .latestnews li {
    border-bottom: 1px dashed rgba(139, 139, 139, 0.3) !important;
  }
  div.tztwd-tweet-container {
    &:last-child {
      border-bottom: none !important;
    }
  }
  .flickr {
    padding-bottom: 12px;
  }
  .latestnews {
    padding-bottom: 14px;
  }
  .style1 .module-title {
    text-transform: uppercase;
    &:after {
      border-color: rgba(204, 204, 204, 0.3);
      margin-top: 2px;
    }
    > span {
      background: @black;
      letter-spacing: 1px;
      font-size: 18px;
      font-weight: 600;
      &:before {
        margin-top: -4px;
      }
    }
  }
  .search {
    padding-bottom: 17px;
    position: relative;
    .button {
      position: absolute;
      top: 0;
      right: 0;
      background: transparent;
      border: none;
      color: transparent;
      &:after {
        content: '\f002 ';
        display: block;
        color: @white;
        font-family: FontAwesome;
        font-size: 16px;
        position: absolute;
        top: 13px;
        right: 30px;
      }
    }
  }
  ul.mod_tz_tag {
    padding-bottom: 12px;
    li {
      a {
        background: #252525;
        &:hover {
          background: @color_1;
          color: @white;
        }
      }
    }
  }
  div.tztwd-tweet-container {
    border-color: rgba(139, 139, 139, 0.3) !important;
  }
  .tz-menu {
    margin-right: -30px;
    margin-top: -13px;
    padding-bottom: 45px;
    .dropdown-menu {
      left: 100%;
      top: 0;
      background: @black;
      border-radius: 0;
      margin: 0;
      padding: 0;
      border: none;
      min-width: 200px;
      li {
        a {
          padding: 8px 20px;
        }
        .tzicon-dropdown {
          display: none;
        }
        &:last-child {
          > a {
            border-bottom: none !important;
          }
        }
      }
    }
    li {

      &.open > a, &:hover > a, &.active > a {
        color: @white !important;
      }
      a {
        border-bottom: 1px dashed rgba(139, 139, 139, 0.3) !important;
        color: #8b8b8b !important;
        padding: 8px 0;
        font-weight: 600;
        letter-spacing: 1px;
        font-size: 15px;
      }

    }
    > li {
      padding-right: 30px;
      &:first-child {
        > a {
          //padding-top: 0;
        }
      }
    }
    .tzicon-dropdown {
      float: right;
      color: @white;
      height: 18px;
      width: 22px;
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      border-radius: 2px;
      background: @color_1;
      text-align: center;
    }
  }
}

#tz-sidebar-click {
  padding: 0;
}

.tz-theme-panel {
  position: fixed;
  top: 50%;
  bottom: 0;
  left: auto;
  width: 180px;
  right: -180px;
  -webkit-transform: translateX(0) translateY(-50%);
  -moz-transform: translateX(0) translateY(-50%);
  -ms-transform: translateX(0) translateY(-50%);
  -o-transform: translateX(0) translateY(-50%);
  transform: translateX(0) translateY(-50%);
  .transition(all 0.3s ease 0s);
  z-index: 99999;
  &.left {
    left: -180px;
    right: auto;
    &.active {
      left: 0;
    }
    .theme-panel {
      .click {
        right: -44px;
        left: auto;
        -webkit-border-radius: 0 3px 3px 0;
        -moz-border-radius: 0 3px 3px 0;
        border-radius: 0 3px 3px 0;
      }
    }
  }
  &.active {
    right: 0;
  }
  &.tz-close {
    right: 0;
  }
  .theme-panel {
    position: relative;
    border: 6px solid rgba(0, 0, 0, 0.7);
    .click {
      position: absolute;
      left: -44px;
      top: 20px;
      font-size: 18px;
      color: #e1e1e1;
      background: rgba(0, 0, 0, 0.7);
      height: 38px;
      width: 38px;
      text-align: center;
      -webkit-border-radius: 3px 0 0 3px;
      -moz-border-radius: 3px 0 0 3px;
      border-radius: 3px 0 0 3px;
      line-height: 1em;
      cursor: pointer;
      i {
        line-height: 38px;
      }
    }
    .ds-table-cell {
      text-align: center !important;
    }
  }
  .text {
    background: rgba(0, 0, 0, 0.7);
    color: @white;
    font-size: 12px;
    font-weight: 300;
    text-transform: uppercase;
    padding: 10px 0 5px;
  }
  .boxs {
    .box {
      position: relative;
      border-bottom: 6px solid rgba(0, 0, 0, 0.7);
      &:last-child {
        border-bottom: none;
      }
      img {
        width: 100%;
      }
      .content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
      a {
        text-transform: uppercase;
        color: @white;
        font-size: 14px;
        font-weight: bold;
      }

    }

  }
}