@media (max-width: 480px) {

  .tz-header {
    top: 40px;
    &.style_1 {
      top: 40px;
      &.tz-fixed {
        .navbar-inner .btn-menu {
          padding: 20px 0;
        }
      }
      .navbar-inner .btn-menu {
        padding: 17px 0 10px;
      }
      #tzlogo {
        padding: 10px 0;
        img {
          max-width: 100px;
        }
      }
      .navbar-inner {

      }
    }
  }

  .btn-menu .menu-toggle {
    height: 14px;
    width: 20px;
    padding: 0;
    margin: 0 15px 0;
    &.active {
      .bar1 {
        -webkit-transform: rotate(45deg) translateX(4px) translateY(4px);
        -moz-transform: rotate(45deg) translateX(4px) translateY(4px);
        -ms-transform: rotate(45deg) translateX(4px) translateY(4px);
        -o-transform: rotate(45deg) translateX(4px) translateY(4px);
        transform: rotate(45deg) translateX(4px) translateY(4px);
      }
      .bar3 {
        -webkit-transform: rotate(-45deg) translateX(4px) translateY(-4px);
        -moz-transform: rotate(-45deg) translateX(4px) translateY(-4px);
        -ms-transform: rotate(-45deg) translateX(4px) translateY(-4px);
        -o-transform: rotate(-45deg) translateX(4px) translateY(-4px);
        transform: rotate(-45deg) translateX(4px) translateY(-4px);
      }
    }
  }

  #plazart-mainnav .plazart-megamenu ul li a {
    font-size: 14px;
  }

  .plazart-mainnav .plazart-megamenu ul.level0 li a {
    padding: 5px 20px !important;
  }

  .plazart-mainnav .plazart-megamenu ul ul {
    padding: 0 10px !important;
  }

  .tz_parallax .quote-content {
    font-size: 24px;
  }

  .tz-absoulte .tz-content {
    &.single_image {
      position: relative;
    }
  }

  .tz_testimonial-multiple {
    > div {
      padding-bottom: 15px;
    }
    .tz-media, .tz-content {
      display: block;
      margin: auto;
      text-align: center;
    }
    .tz-content {
      padding-left: 0;
    }
  }

  .tz-menu-footer-page li a {
    font-size: 9px;
    padding: 0 10px;
  }

  .tp-bullets.custom .bullet {
    width: 7px;
    height: 20px;
  }

  .quote_style_1 {
    font-size: 24px;
    padding: 40px 0 30px;
  }

  .quote_author_style_1 {
    font-size: 16px;
  }

  .style3 {
    .custom_text_mod {
      width: 100%;
      font-size: 12px;
      padding: 10px 0;
      line-height: 2em;
    }
    .mod_title {
      font-size: 24px;
    }
  }

  .style3.ver2 .module-title .mod_title {
    font-size: 30px;
    &:after {
      left: 50%;
      margin-left: -70px;
      top: 16px;
    }
  }

  .tztwd_parallax div.tztwd-tweet-container .tztwd-tweet-data, .tztwd_parallax div.tztwd-tweet-container .tztwd-tweet {
    font-size: 18px;
  }

  .tztwd-twitter-icon {
    width: 80px;
    height: 80px;
    i {
      height: 67px;
      width: 67px;
      line-height: 71px;
    }
  }

  .offer .text {
    &.text-1 {
      font-size: 16px;
    }
    &.text-2 {
      font-size: 13px;
    }
  }

  .TzContact {
    width: 100%;
  }

  .tz_creative .tz_item_default {
    padding: 15px 0;
    .tz-content {
      .tz_title {
        font-size: 24px;
        padding-bottom: 30px;
        &:after {
          top: 50px;
        }
      }
      .tz_category {
        font-size: 13px;
      }
      .tz_description {
        .text-1 {
          font-size: 16px;
        }
        .text-2 {
          font-size: 13px;
        }
      }
    }
    .tz-media {
      .tz_image {
        padding: 0 !important;
      }
    }
  }

  .menu-style-home-1 .nav li > a {
    font-size: 24px;
  }

  .tzCountdown {
    .countdown-section {
      padding: 0 10px;
      .countdown-amount {
        font-size: 36px;

      }
      .countdown-period {
        font-size: 8px;
        width: 10px;
      }
    }
    &:after {
      clear: both;
      content: '';
      display: block;
    }
  }

  .mod_coutndown .custom_text_countdown {
    .style_3 {
      width: 100%;
      font-size: 16px;
    }
    .style_2 {
      font-size: 24px;
    }

  }

  .mod_coutndown .custom_text_bottom_countdown p {
    font-size: 20px;
  }

  .owl-carousel.testimonial-slide {
    width: 100%;
  }

  .style3 .module-title {
    padding: 0 0 30px 0 !important;
  }

  .tz_filter .option-combo a {
    margin: 5px;
  }

  .view-all-projects {
    padding-top: 45px;
    .title {
      font-size: 18px;
      padding-bottom: 20px;
    }
  }

  .tz_filter {
    padding-bottom: 30px;
  }

  .owl-carousel.testimonial-slide .owl-item .tz-content {
    padding-top: 25px;
    padding-bottom: 15px;
    .quote {
      padding-bottom: 15px;
      font-size: 14px;
    }
  }

  .skill-count-item .tz_media {
    padding-left: 40px;
    min-width: 85px;
  }

  .models .owl-controls {
    padding-top: 50px;
  }

  .custom_text_top_count {
    padding-bottom: 10px;
    .style_1 {
      font-size: 18px;
    }
  }

  .home3 {
    .video .tz_btn_easy {
      font-size: 20px;
      padding: 21px 28px;
      margin-left: -38px;
      bottom: 80px;
    }
    .tz-header {
      top: 20px;
      padding: 0;
      &.tz-fixed {
        //padding: 10px 0;
        //top: 0;
      }
      .tz-logo {
        img {
          max-width: 80px;
        }
      }
      .btn-menu {
        padding: 3px 0;
        .menu-toggle {
          margin: 0;
        }
      }
    }
    .tz_content {
      width: 100%;
      padding: 70px 15px;
      z-index: 999;

      height: auto;
      .text-medium {
        font-size: 13px !important;
      }
      .text-small {
        font-size: 11px !important;
      }
      .text.style1 {
        margin-bottom: 10px;
      }
      .info {
        padding-bottom: 0;
      }
      .content_custom {
        width: 100%;
      }
    }
    .home3_title_offer {
      font-size: 18px;
      margin: 0;
      &:after {
        //margin: 7px 0 15px;
      }
    }
    .about-team-home3 {
      .tz_content {
        .content_custom {
          width: 100%;
        }
      }
      .model-box-1 {
        padding: 0 15px 0;
        .team-img, .team-details {
          margin-right: 20px;
          margin-left: 20px;
        }
      }
      .title {
        font-size: 11px;
      }
      .position {
        font-size: 10px;
      }
    }
    .slider .flexslider .flex-control-nav {
      display: none;
    }
    .tz_content .text {
      font-size: 13px;
    }
    .text-center-xs {
      text-align: center;
    }
  }

  #pp-nav {
    display: none;
  }

  .banner_page {
    height: 300px !important;
  }

  .tz-breadcrumb h2.bread_last {
    font-size: 36px;
  }

  .tz-breadcrumb .breadcrumb li a, .tz-breadcrumb .breadcrumb li span {
    font-size: 12px;
  }

  #toTop .toTop {
    right: 5px;
    width: 30px;
    height: 30px;
    i {
      font-size: 19px;
      line-height: 30px;
    }
  }

  .clients-grid .client-wrap {
    width: 100% !important;
    border: none;
  }

  .TzBlogTitle {
    font-size: 24px;
  }

  .block_info {
    padding: 25px 15px 15px;
  }

  .TzQuote {
    .block_info {
      position: relative;
      .text {
        font-size: 16px;
      }
    }
  }

  .tz-xs-padding-100 {
    padding: 100px 0;
  }

  .parallax {
    padding: 120px 0 !important;
  }
}