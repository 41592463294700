@media (max-width: 1200px) {
  .home3 .tz_content {
    padding: 0 30px;
    .content_custom {
      width: 95%;
    }
  }

  .home3 {
    .client_home3 {
      padding-right: 15px;
      padding-left: 15px;
      .clients-logo {
        min-height: 130px;
        padding-right: 15px;
        img {
          height: auto;
          max-width: 100%;
        }
      }
    }
    .tz_content .text {
      font-size: 18px;
    }
    .tz-header {
      &.tz-fixed {
        padding: 10px 15px;
        top: 0;
      }
    }
  }

  .tz-gallery .option-combo .option-set a {
    margin: 5px;
  }

}

@media (max-width: 1024px) {
  .home3 {
    .slider {
      .tz_content {
        position: absolute;
        height: 100%;
      }
      .flexslider .flex-control-nav {
        z-index: 999;
        left: auto !important;
        right: auto !important;
      }
    }
    .video {
      .tz_content {
        padding-bottom: 200px;
      }
      .tz_btn_easy {
        left: 50%;
        right: auto !important;
        margin-left: -60px;
        top: auto;
        bottom: 60px;
      }
    }
    .tz_content {
      position: relative;
      width: 100%;
      padding-top: 100px;
      padding-bottom: 100px;
      background: rgba(13, 22, 41, 0.5);
      .content_custom {
        width: 100%;
      }
    }
    .about-team-home3 .model-box-1 {
      .team-img, .team-details {
        margin-right: 0;
      }
    }
  }

  .parallax {
    background-attachment: scroll !important;
    background-position: 50% 0 !important;
  }
}

@media (max-width: 1366px) and (min-width: 1200px) {
  .home3 .about-team-home3 .model-box-1 .team-img,
  .home3 .about-team-home3 .model-box-1 .team-details {
    margin-left: 40px;
    margin-right: 40px;
  }
}

@media (max-width: 1366px) and(min-width: 991px) {
  .TzQuote .block_info .text {
    font-size: 14px;
  }
}