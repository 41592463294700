
.home3 {
  .tz-header {
    top: 30px;
    padding: 0 15px;
    position: fixed;
  }
  .btn-menu .menu-toggle {
    margin-right: 45px;
  }
  .offer {
    video {
      position: relative;
    }
    .bg-video {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
    }
  }
  .tz_btn_easy {
    position: absolute;
    top: 50%;
    color: @color_1;
    font-size: 30px;
    padding: 30px 38px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.15);
    border: 1px solid rgba(255, 255, 255, 0.6);
    margin-top: -50px;
    z-index: 1111;
  }
  .home3_title_offer {
    font-size: 30px;
    color: @white;
    text-transform: uppercase;
    &.style1 {
      padding-bottom: 10px;
    }
    a {
      line-height: 1.2em;
      font-weight: 600;
    }
    &:after {
      content: '';
      display: block;
      position: relative;
      width: 70px;
      height: 3px;
      background: @color_1;
      margin: 17px 0 30px;
    }
  }
  .bg-right {
    position: absolute;
    right: -28%;
    top: -6%;
    background: rgba(255, 255, 255, 0.1);
    height: 90%;
    width: 70%;
    -webkit-border-radius: 0 0 0 100%;
    -moz-border-radius: 0 0 0 100%;
    border-radius: 0 0 0 100%;
    z-index: 2;
  }
  .offer {
    &:after {
      content: '';
      display: block;
      clear: both;
    }
  }
  .about-team-home3 {
    .team-img {
      img {
        width: 100%;
      }
    }
    .ds-table-cell {
      text-align: center;
    }
    .model-box-1 {
      padding: 30px 15px 10px;
      .team-img, .team-details {
        margin-right: 45px;
      }
      .team-img {
        margin-bottom: 20px;
      }
    }
    .team-socials li a {
      margin: 2px;
      i {
        font-size: 15px;
        line-height: 30px;
        height: 30px;
        width: 30px;
      }
    }
    .title {
      font-size: 15px;
      letter-spacing: 0;
      font-weight: 500;
    }
    .position {
      font-size: 12px;
    }
  }
  .client_home3 {
    padding-top: 50px;
    .clients-logo {
      min-height: 150px;
      padding-right: 0;
      img {
        height: 100%;
        width: auto;
        margin: auto;

      }
    }
  }
  .text-lagre {
    font-size: 46px;
    &.home3_title_offer:after {
      width: 100px;
    }
  }
  .text-medium {
    font-size: 18px !important;
  }
  .team-img {
    position: relative;
    &:hover {
      .overlay {
        opacity: 1;
        visibility: visible;
        &:before {
          top: 10px;
          left: 10px;
          right: 10px;
          bottom: 10px;
        }
      }
    }
  }
  .flexslider {
    overflow: hidden;
    &:before {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      content: '';
      background: rgba(0, 0, 0, 0.45);
      height: 100%;
      z-index: 11;
    }
  }
  .slider {
    position: relative;
    li {
      img {
        position: relative;
      }
    }
    .flexslider .flex-control-nav {
      z-index: 111;
      right: 100px;
    }
  }
  .ds-table-cell {
    text-align: left;
  }
  .tz_content {
    position: absolute;
    width: 50%;
    height: 100%;
    top: 0;
    background: rgba(13, 22, 41, 0.85);
    z-index: 111;
    padding-left: 110px;
    padding-right: 110px;
    color: @white;
    &:after {
      content: '';
      display: block;
      clear: both;
    }
    &.contact {
      .text {
        font-size: 16px;
        color: @white;
      }
      .home3_title_offer {
        margin-top: 55px;
      }
    }
    &.content_style1 {
      width: 100%;
      .content_custom {
        height: 100%;
      }
    }
    a {
      color: @white;
    }
    .text {
      color: #dddddd;
      font-size: 15px;
      line-height: 1.7em;
      margin-bottom: 10px;
      &.style1 {
        margin-bottom: 30px;
      }
    }
    .content_custom {
      width: 70%;
      &:after {
        content: '';
        display: block;
        clear: both;
      }
    }
    .title_offer:before {
      background: @color_1;
    }
    .info {
      padding-bottom: 27px;;
    }
  }
  .tztwd {
    display: block;
    position: relative;
    height: 100%;
    width: 100%;
    .tztwd-child {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      .tztwd-tweet {
        width: 70%;
        margin: auto;
        font-size: 24px;
        padding-bottom: 45px;
        padding-top: 45px;
        line-height: 1.5em;
      }
      .owl-controls {
        margin-top: 20px;
      }
      .tztwd-data {
        font-size: 13px;
      }
    }
  }
  .tztwd-twitter-icon {
    height: 70px;
    width: 70px;
    line-height: 1;
    border: 6px solid rgba(224, 0, 108, 0.8);
    i {
      line-height: 58px;
      height: 58px;
      width: 58px;
    }
  }

  .section:nth-child(2n+1) {
    .tz_content {
      right: 0;

    }
    .tz_btn_easy {
      left: 25%;
      right: auto;
    }
    .slider .flexslider .flex-control-nav {
      right: 100px;
    }
  }
  .section:nth-child(2n) {
    .tz_content {
      left: 0;
      .content_custom {
        float: right;
      }
    }
    .slider .flexslider .flex-control-nav {
      left: 100px;
    }
    .tz_btn_easy {
      right: 25%;
    }
  }

}