.logout,
.login-wrap {
  .login {
    .tz_input {
      input, textarea {
        width: 100%;
        border: 1px solid #e7e7e7;
        -webkit-border-radius: 2px;
        -moz-border-radius: 2px;
        border-radius: 2px;
        padding: 15px 20px;
        color: #818d9a;
        font-size: 14px;
        line-height: 1em;
      }
    }
    .page-header {
      border: none;
      color: #363d4f;
      padding: 5px 0 25px;
      margin: 0;
      h2 {
        margin: 0;
      }
    }
    .tz_label label,
    .form-group {
      margin-bottom: 10px;
    }
    .form-group.other-links {
      text-align: right;
      margin-top: 10px;
      a {
        color: @color_1;
        text-decoration: underline;
      }
    }
    .checkbox {
      padding: 0;
      margin: 10px 0 0;
      label {
        min-height: 0;
        margin: 0;
        padding: 0;
      }
      input {
        position: relative;
        margin: 0 5px 0 0;
        top: 2px;
      }
    }

  }
  .btn {
    width: 100%;
    display: block;
    background: #4c5866;
    border-color: #4c5866;
    text-transform: uppercase;
    font-weight: 700;
    padding: 10px 0;
    line-height: 1.9em;
    letter-spacing: 1px;
  }
}

.logout {
  width: 70%;
  background: #e7e7e7;
  padding: 30px;
  margin: auto;
}

.profile {
  dt,
  dd {
    color: #8b8b8b;
  }
  .btn:focus {
    color: #fff;
  }
}

.contact {
  text-align: center;
  .form-group {
    position: relative;
    i {
      position: absolute;
      top: 15px;
      right: 30px;
      color: #e7e7e7;
    }
    .checkbox {
      margin: 0 0 20px;
      label {
        padding: 0;
        color: #3d3d3d;
      }
    }
  }
  .contact-address {
    padding: 70px 0 40px;
    font-size: 16px;
  }
  input[type="checkbox"] {
    width: auto !important;
  }
  .contact-links {
    ul {
      li {
        display: inline-block;

      }
    }
  }
  a {
    color: #3d3d3d;
    &:hover {
      color: @color_1;
    }
  }
  .contact-misc {
    padding: 15px 30px;
    color: #3d3d3d;
    font-size: 14px;
    line-height: 1.5em;
  }
}

.contact,
.profile,
.profile-edit,
.registration,
.reset,
.remind {
  p {
    margin: 0 0 20px;
  }
  fieldset {
    margin: 15px 0;
  }
  .form-group {
    input, textarea {
      width: 100%;
      border: 1px solid #e7e7e7;
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      border-radius: 2px;
      padding: 15px 20px;
      color: #818d9a;
      font-size: 14px;
      line-height: 1em;
    }
    .tz_label {
      padding-top: 15px;
    }
  }
  .btn {
    width: 100%;
    display: block;
    background: #4c5866;
    border-color: #4c5866;
    text-transform: uppercase;
    font-weight: 700;
    padding: 10px 15px;
    line-height: 1.9em;
    letter-spacing: 1px;
    color: @white;
  }
  #helpsite-refresh {
    width: 120px;
    display: inline-block;
    padding: 0 5px;
    color: @white;
  }
}

.newsfeed,
.newsfeed-category,
.categories-list,
.blog-featured,
.contact-categories,
.contact-category {
  .filters {
    margin-bottom: 10px;
  }
  h2 {
    font-weight: 700;
  }
  .list-title {
    a {
      color: #363d4f;
      &:hover {
        color: @color_1;
      }
    }
  }
  .item-title {
    a {
      color: @color_1;
    }
  }
  input[type="text"] {
    width: 100%;
    border: 1px solid #e7e7e7;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    padding: 15px 20px;
    color: #818d9a;
    font-size: 14px;
    line-height: 1em;
  }
  a {
    color: @color_1;
  }
}

.blog-featured {
  .btn {
    margin-top: 10px;
  }
  legend {
    padding: 10px 0px;
    border-bottom: 1px dashed #bbbbbb;
    margin-bottom: 15px;
  }
  .item-title,
  th {
    a {
      color: #666666;
      &:hover {
        color: @color_1;
      }
    }
  }
  td {
    color: #9d9d9d;
  }
  li {
    a {
      color: #666666;
      &:hover {
        color: @color_1;
      }

    }
  }
  ul {
    list-style-type: none;
    li {
      border-left: 4px solid @color_1;
      padding-left: 8px;
      margin-bottom: 10px;
      color: #8b8b8b;
    }
  }
  p {
    font-weight: 400;
    color: #8b8b8b;
  }
  h2 {
    margin: 20px 0px;
    font-size: 24px;
    font-weight: 600;
    color: #363d4f;
    transition: 400ms;
    &:hover {
      color: @color_1;
      text-decoration: none;
    }
    a {
      font-size: 26px;
      font-weight: 700;
      color: #363d4f;
      transition: 400ms;
      &:hover {
        color: @color_1;
        text-decoration: none;
      }
    }
  }
}

.pagination-wrap {
  &:after {
    display: block;
    content: '';
    clear: both;
  }
  .pagination > li {
    display: inline-block;
    a {
      background: transparent !important;
      border: none;
      color: #3d3d3d;
    }
    &.active > a {
      color: @color_1;
    }
  }
}

.search {
  #searchForm {
    i {
      color: @color_1;
    }
  }
  legend {
    padding: 0 0 15px;
  }
  label {
    color: #8b8b8b;
  }
  .result-title {
    a {
      color: #363d4f;
    }
  }
  .result-text {
    color: #8b8b8b;
  }
  .result-created {
    border-bottom: 1px dashed #8b8b8b;
    color: #8b8b8b;
    padding-bottom: 10px;
    margin-bottom: 10px;
  }
}

.finder {
  .btn {
    background-color: #363D4F;
    border-color: #363D4F;
    opacity: 100;
    &:hover {
      background-color: @color_1;
      border-color: @color_1;
    }
    &:focus {
      background-color: @color_1;
      border-color: @color_1;
    }
  }
  #finder-filter-window {
    input {
      width: 80%;
      border: 1px solid #e7e7e7;
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      border-radius: 2px;
      padding: 15px 20px;
      color: #818d9a;
      font-size: 14px;
      line-height: 1em;
    }
    .btn {
      margin-left: 15px;
      [class^="icon-"] {
        color: @white;
      }
    }
    select {
      padding: 10px 15px;
      margin: 15px 0;
    }
    .search-results {
      li {
        padding: 20px;
      }
    }
  }

}

.full-content {
  .finder,
  .search,
  .newsfeed,
  .newsfeed-category,
  .categories-list,
  .blog-featured,
  .contact-categories,
  .contact-category {
    background: #e7e7e7;
    width: 70%;
    padding: 30px;
    margin: auto;
  }
  .profile,
  .profile-edit,
  .registration,
  .reset,
  .remind {
    width: 70%;
    background: #e7e7e7;
    margin: auto;
    padding: 60px 30px;
  }

  .logout,
  .login-wrap {
    .login {
      width: 40%;
      margin: auto;
      background: #f7f7f7;
      padding: 30px;
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      border-radius: 2px;
      .box-shadow(0 0 5px rgba(0, 0, 0, 0.1));
    }
  }
}

.TzUserInner {
  .tz_portfolio_user {
    margin: 0 0 30px 0;
    background: @white;
    padding: 15px;
    .media-heading {
      margin-bottom: 10px;
      a {
        font-weight: 600;
        text-transform: uppercase;
      }
    }
    .AuthorAvatar {
      padding-right: 0;
    }
    .media-body {
      > p {
        font-size: 90%;
      }
    }
  }
}

.TzTagInner {
  .content_rating {
    display: inline-block;
  }
  .TzTagMedia {
    margin: 0;
  }
  .TzTagHeading {
    margin: 0;
    padding-bottom: 30px;
  }
}

.date-group {
  .text-info {
    color: @color_1;
    border-color: @color_1;
  }
}