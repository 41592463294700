@media (max-width: 1200px) and(min-width: 992px) {
  .tz_creative .tz_item_default .tz_description {
    .text-1 {
      font-size: 16px;
    }
    .text-2 {
      font-size: 14px;
    }
  }

  .style3.ver3 .custom_text_mod {
    font-size: 16px;
  }
}
